import {
  action, computed, observable
} from 'mobx';
import {
  ACCESS_TOKEN_EXP_KEY, ACCESS_TOKEN_KEY, USER_DATA_KEY
} from '../constants/auth';
import analytics from '../services/analytics';
import type { IInitAccountParams } from '../services/AuthService';
import AuthService from '../services/AuthService';
import SessionService from '../services/generic/SessionService';
import type RootStore from '../stores/RootStore';
import type { IMobxStore } from '../typings';
import { CrmIdentityProperties } from '../utils/analytics';
import {
  cookies, secureStorage
} from '../utils/storage';
import {
  AccountSetupCompletedEvent,
  LoggedInEvent,
  LoggedOutEvent,
  PasswordResetRequestedEvent,
  SignedUpEvent,
  UserProfileUpdatedEvent
} from '../services/analytics/AnalyticsEvents';
import liveChatService from '../services/live-chat/LiveChatService';

export type TPrimaryUse = 'PERMIT_PACKAGES' | 'PROPOSALS';
export type TAccountType = 'INSTALLATION_COMPANY' | 'HOMEOWNER' | 'DESIGN_COMPANY';

export interface IUser {
  id: string;
  account?: IAccount;
  firstName: string;
  lastName: string;
  picture: string;
  email: string;
  phoneNumber?: string;
  primaryUse?: TPrimaryUse;
  accountAdmin?: boolean;
  applicationAdmin?: boolean;
}

interface IAccount {
  id: string;
  type?: TAccountType;
  installer?: string;
}

export interface IAccessToken {
  value: string;
  expiration: number;
}

export interface ISessionInfo {
  accessToken: IAccessToken;
  user: IUser;
  /**
   * No account field present in session response indicates the user has not yet passed initial account setup
   */
  account?: IAccount;
}

export interface ILoginParams {
  email: string;
  password: string;
  rememberMe?: boolean;
}

export interface ISignUpParams extends ILoginParams {
  firstName: string;
  lastName: string;
}

class AuthStore implements IMobxStore {
  @observable user: IUser = {} as IUser;
  @observable accessTokenExp: number = 0;
  @observable loginError: string | null = null;
  @observable signUpError: string | null = null;
  @observable resetError: string | null = null;
  @observable updateAccountError: string | null = null;
  @observable passResetError: string | null = null;
  @observable passResetDone: boolean = false;
  @observable isLoading: boolean = false;

  private readonly rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    window.addEventListener('LyraConfirmModalClosed', () => {
      this.passResetDone = false;
    });
  }

  @computed
  get tokenIsValid(): boolean {
    const currentTimeStamp = Math.round(Date.now() / 1000);
    return this.accessTokenExp > currentTimeStamp;
  }

  @computed
  get isAuthorized(): boolean {
    const tokenExist = !!SessionService.accessToken && !!this.accessTokenExp;
    return tokenExist && this.tokenIsValid;
  }

  @computed
  get isApplicationAdmin(): boolean {
    return this.user?.applicationAdmin === true;
  }

  @computed
  get isInstallationCompany(): boolean {
    return this.user?.account?.type === 'INSTALLATION_COMPANY';
  }

  @computed
  get isHomeowner(): boolean {
    return this.user?.account?.type === 'HOMEOWNER';
  }

  @computed
  get isDesignCompany(): boolean {
    return this.user?.account?.type === 'DESIGN_COMPANY';
  }

  @computed
  get accountId(): string {
    return this.user?.account?.id || '';
  }

  /**
   * This method is called once on app start, to check if user is already authenticated or not.
   */
  @action
  restoreSession = (): void => {
    // eslint-disable-next-line no-console
    console.log('Attempting to restore session');
    const accessToken = secureStorage.get(ACCESS_TOKEN_KEY);
    const accessTokenExpStr = secureStorage.get(ACCESS_TOKEN_EXP_KEY);
    const user = secureStorage.get(USER_DATA_KEY) || cookies.get(USER_DATA_KEY);
    if (accessToken && accessTokenExpStr && user) {
      this.accessTokenExp = parseInt(accessTokenExpStr, 10);
      const {
        account: accountData, ...userWithoutAccountData
      } = user;
      const sessionData = {
        user: userWithoutAccountData,
        account: accountData,
        accessToken: {
          value: accessToken,
          expiration: accessTokenExpStr
        }
      };

      if (this.tokenIsValid) {
        this.handleSessionData(sessionData, true);
        this.rootStore.account.loadInstallerOptions();
      } else {
        this.resetTokenState();
      }
    } else if (user) {
      this.user = user;
      // Identify who is visiting/returning without an accessToken
      const identifyCrmId = analytics.analyticsSessionId() || user?.id;
      analytics.identify(CrmIdentityProperties(user, identifyCrmId));
    } else {
      // Here we call analyticsSessionId to check for valid CRM id, then we make
      // an empty identify() call in case there is a valid CRM user being synced
      analytics.analyticsSessionId();
      analytics.identify();
    }
    // Initialize live chat widget
    if (this.tokenIsValid && this.user.email) {
      liveChatService.setUpChatWidgetForAuthenticatedUser(this.user.email);
    } else {
      liveChatService.setUpChatWidgetForAnonymousVisitor();
    }
  };

  @action
  login = async (params: ILoginParams): Promise<void> => {
    const {
      rememberMe, ...otherParams
    } = params;
    try {
      const sessionInfo: ISessionInfo = await AuthService.login(otherParams);
      const identifyCrmId = analytics.analyticsSessionId() || this.user.id;
      // adding user email to the response for easier use later
      sessionInfo.user.email = params.email;
      this.handleSessionData(sessionInfo, rememberMe);
      this.rootStore.account.loadInstallerOptions();
      analytics.trackEvent(new LoggedInEvent(sessionInfo.user, identifyCrmId));
      liveChatService.setUpChatWidgetForAuthenticatedUser(params.email);
    } catch (e) {
      this.signUpError = (e as Error).message;
    }
  };

  @action
  signUp = async (params: ISignUpParams): Promise<void> => {
    try {
      const signedUpUserSessionInfo: ISessionInfo = await AuthService.signUp(params);
      const identifyCrmId = analytics.analyticsSessionId() || signedUpUserSessionInfo.user.id;
      analytics.identify(CrmIdentityProperties(signedUpUserSessionInfo.user, identifyCrmId));
      analytics.trackEvent(new SignedUpEvent(signedUpUserSessionInfo.user, identifyCrmId));
      this.rootStore.lead.convertLead(signedUpUserSessionInfo.user, identifyCrmId);
    } catch (e) {
      this.signUpError = (e as Error).message;
      throw e;
    }
  };

  @action
  passwordReset = async (email: string): Promise<void> => {
    try {
      await AuthService.passwordReset(email);
      this.passResetDone = true;
      analytics.trackEvent(new PasswordResetRequestedEvent(email));
    } catch (e) {
      this.resetError = (e as Error).message;
    }
  };

  @action
  logout = (): void => {
    const identifyCrmId = analytics.analyticsSessionId() || this.user.id;
    analytics.trackEvent(new LoggedOutEvent(this.user, identifyCrmId));
    this.resetTokenState();
    this.resetUserState();
    liveChatService.setUpChatWidgetForAnonymousVisitor();
  };

  /**
   * Reset access token state only to it's default values
   */
  @action
  private resetTokenState = (): void => {
    SessionService.updateToken(null, SessionService.rememberMe);
    this.accessTokenExp = 0;

    secureStorage.remove(ACCESS_TOKEN_KEY);
    secureStorage.remove(ACCESS_TOKEN_EXP_KEY);
  };

  /**
   * Reset all the the state to it's default values
   */
  @action
  private resetUserState = (): void => {
    this.user = {} as IUser;
    secureStorage.remove(USER_DATA_KEY);
    this.loginError = null;
    this.signUpError = null;
    this.resetError = null;
    analytics.reset();
  };

  @action
  completeAccountSetup = (params: IInitAccountParams): void => {
    AuthService.completeAccountSetup(params).then((sessionInfo: ISessionInfo) => {
      this.handleSessionData(sessionInfo, true);
      this.rootStore.account.loadInstallerOptions();
      const identifyCrmId = analytics.analyticsSessionId() || this.user.id;
      analytics.trackEvent(new AccountSetupCompletedEvent(this.user, identifyCrmId));
      this.rootStore.lead.resetLeadState();
      if (sessionInfo.account?.installer) {
        analytics.group(sessionInfo.account.id, { name: params.companyName });
      }
    });
  };

  @action
  private handleSessionData = (data: ISessionInfo, rememberMe = false): void => {
    const {
      accessToken, user, account
    } = data;
    const identifyCrmId = analytics.analyticsSessionId() || user.id;

    this.accessTokenExp = accessToken.expiration;

    // Store info about Account on User object for easier access
    this.user = {
      ...user,
      account
    };

    SessionService.updateToken(accessToken, rememberMe);
    SessionService.initSessionTimeout(this.accessTokenExp);
    SessionService.setUser(this.user);

    // This is used when user logs in first time.
    // But when user already logged in, we don't need to save token anymore.
    if (rememberMe) {
      secureStorage.set(USER_DATA_KEY, this.user);
      localStorage.setItem('_crm_id', identifyCrmId);
      cookies.set(
        USER_DATA_KEY,
        {
          ...user,
          account
        },
        {
          path: '/',
          sameSite: 'lax',
          maxAge: 60 * 60 * 24 * 90, // expires in 90 days,
          secure: true
        }
      );
    }

    // eslint-disable-next-line no-console
    console.log('analytics.identify if user.id', user.id);
    if (user.id) {
      analytics.identify(CrmIdentityProperties(this.user, identifyCrmId));
    }
  };

  @action
  refreshAccessToken = (): Promise<string> => {
    return SessionService.refreshSession().then((session: ISessionInfo) => {
      this.handleSessionData(session);
      return session.accessToken.value;
    });
  };

  @action
  getProfile = (): Promise<void> => {
    this.isLoading = true;
    return AuthService.getProfile()
      .then((user: IUser) => {
        this.user = {
          ...this.user,
          ...user
        };
        const identifyCrmId = analytics.analyticsSessionId() || this.user?.id;
        secureStorage.set(USER_DATA_KEY, this.user);
        localStorage.setItem('_crm_id', identifyCrmId);
        cookies.set(USER_DATA_KEY, user, {
          path: '/',
          sameSite: 'lax',
          maxAge: 60 * 60 * 24 * 90, // expires in 90 days in seconds,
          secure: true
        });
      })
      .finally(() => {
        this.isLoading = false;
      });
  };

  @action
  updateProfile = (userUpdateRequest: IUser): Promise<IUser | void> => {
    // Merge current values with new values and always pass all user fields to BE, because it has strict validations
    const params = {
      primaryUse: this.user.primaryUse,
      ...userUpdateRequest
    };
    return AuthService.updateProfile(params)
      .then((userUpdateResponse: IUser) => {
        this.updateAccountError = '';
        // Note: User update response is not a full `IUser` object - it only has the profile fields
        const fullUpdatedUserObject = {
          ...this.user,
          ...userUpdateResponse
        };
        const identifyCrmId = analytics.analyticsSessionId() || fullUpdatedUserObject.id;
        analytics.identify(CrmIdentityProperties(fullUpdatedUserObject, identifyCrmId));
        analytics.trackEvent(new UserProfileUpdatedEvent(fullUpdatedUserObject, identifyCrmId));
        return userUpdateResponse;
      })
      .catch((e: Error) => {
        this.updateAccountError = e.message;
      });
  };
}

export default AuthStore;
