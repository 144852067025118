/* eslint-disable */
/**
 * Error logging
 *
 * Can be used to log errors to console or and external
 * error logging system, like Sentry.
 *
 */
import * as Sentry from '@sentry/react';
import {
  reportingObserverIntegration,
  extraErrorDataIntegration,
  captureConsoleIntegration
} from '@sentry/integrations';
import config from '../config/config';

const arr = new Array<string>();
export interface ApiError {
  error: string;
  exception: string;
  message: string;
  path: string;
  status: number;
  timestamp: number;
  code: string;
  length: number;
  meta: typeof arr;
  key: string;
  data: {
    errors: typeof arr;
  };
}
interface ISignUpErrorResponse {
  error: string;
  exception: string;
  message: string;
  path: string;
  status: number;
  timestamp: number;
}

const sentry = config.sentry;
const environment = config.environment;
const showErrorDialog = (code: ApiError['code']) => sentry.showDialog && !sentry.ignoredCodes.includes(code);

/**
 * Set up error handling. If a Sentry DSN is
 * provided a Sentry client will be installed.
 */
export const SentrySetup = (): void => {
  if (sentry.enabled && sentry.dsn) {
    // Configures the Sentry client. Adds a handler for
    // any uncaught exception.
    Sentry.init({
      dsn: sentry.dsn,
      environment: sentry.environment,
      release: environment.host.version,
      autoSessionTracking: sentry.autoSessionTracking,
      maxBreadcrumbs: sentry.maxBreadcrumbs,
      attachStacktrace: sentry.attachStacktrace,
      tracesSampleRate: sentry.tracesSampleRate,
      transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
      transportOptions: {
        // limit how many events will be saved locally. Defaults to 30.
        maxQueueSize: sentry.maxStoredEvents
      },
      integrations: [
        new Sentry.BrowserTracing(),
        reportingObserverIntegration(),
        extraErrorDataIntegration({
          // limit of how deep the object serializer should go. Anything deeper than limit will
          // be replaced with standard Node.js REPL notation of [Object], [Array], [Function] or
          // a primitive value. Defaults to 3.
          depth: sentry.dataIntegrationDepth
        }),
        captureConsoleIntegration({
          // array of methods that should be captured
          // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
          levels: ['error', 'warn', 'debug']
        })
      ],
      /*
       * Note: Sentry will instrument matching requests and will add extra `sentry-trace` and `baggage` HTTP headers.
       * Matching patterns can be either regular expressions, or strings (the URL must contain given string).
       * By default it included 'localhost' string, and it caused issues when trying to load HubSpot chat widget,
       * because request URL included a query parameter with `localhost` in it. We don't want to trace such requests, so
       * it was replaced with a regex that expects `localhost` at the start of the URL.
       * See: https://docs.sentry.io/platforms/javascript/performance/instrumentation/automatic-instrumentation/#tracepropagationtargets
       */
      tracePropagationTargets: [/^http:\/\/localhost/, config.site.canonicalRootUrl, /^\//]
    });
    console.log(`Sentry has been loaded for Lyra Host version ${environment.host.version}`);
  }
};

/**
 * Set user ID for the logger so that it
 * can be attached to Sentry issues.
 *
 * @param {String} userId ID of current user
 */
export const SentrySetUserId = (userId: string) => {
  Sentry.getCurrentScope().setUser({ id: userId });
};

/**
 * Clears the user ID.
 */
export const SentryClearUserId = () => {
  Sentry.getCurrentScope().setUser(null);
};

/**
 * Logs a front end execption. If Sentry is configured
 * sends the error information there. Otherwise
 * prints the error to the console.
 *
 * @param {Error} e Error that occurred
 * @param {String} message Error that occurred
 */
export const SentryException = (message: string, e: Error | ISignUpErrorResponse) => {
  const scope = new Sentry.Scope();
  scope.setTag('message', message);
  if (sentry.enabled && sentry.dsn) {
    Sentry.captureException(e, () => scope);
    if (e && sentry.showDialog) {
      Sentry.showReportDialog({ eventId: e.message });
    }
    console.error(message, e);
  } else {
    console.error(message, e);
  }
};

/**
 * Logs a full API exception. If Sentry is configured
 * sends the error information there. Otherwise
 * prints the error to the console.
 *
 * @param {Error} e Error that occurred
 * @param {String} code Error code
 * @param {Object} data Additional data to be sent to Sentry
 */
