import styled from 'styled-components';

export const Header = styled.div`
  text-align: center;
`;

export const Footer = styled.div`
  text-align: center;
  margin-top: 50px;
`;

export const CompanyFormWrapper = styled.div`
  height: 170px;
  flex: 1 0 auto;
`;

export const ButtonContainer = styled.div`
  & .disabled {
    cursor: not-allowed;
    & button {
      opacity: 0.5;
      pointer-events: none;
    }
  }
`;
