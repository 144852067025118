import styled from 'styled-components';
import {
  MESSAGE_BACKGROUND_COLOR, MESSAGE_BORDER_COLOR
} from '../../constants';
import type { TAlertType } from './AlertMessage';
type Props = {
  isMessageVisible?: boolean;
  type: TAlertType;
};

export const AlertMessageBox = styled.div<Props>`
  box-sizing: border-box;
  display: ${({ isMessageVisible }: Props): string => (isMessageVisible ? 'block' : 'none')};
  width: 100%;
  padding: 15px 12px;
  border: solid 1px ${({ type }: Props): string => MESSAGE_BORDER_COLOR[type]};
  background-color: ${({ type }: Props): string => MESSAGE_BACKGROUND_COLOR[type]};
  border-radius: 0.25rem;
  font-size: 14px;
  line-height: 18px;
  font-family: 'Mukta', sans-serif;
`;
