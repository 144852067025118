import type {
  ApiResponse, ApisauceInstance
} from 'apisauce';
import { create } from 'apisauce';
import {
  action, observable
} from 'mobx';
import config from '../../config/config';
import {
  ACCESS_TOKEN_EXP_KEY, ACCESS_TOKEN_KEY, USER_DATA_KEY
} from '../../constants/auth';
import type {
  IAccessToken, ISessionInfo, IUser
} from '../../stores/AuthStore';
import { secureStorage } from '../../utils/storage';
import type { IHttpServiceParams } from './HttpService';

class SessionService {
  @observable
  accessToken: string | null = null;
  accessTokenExp: number | null = null;

  rememberMe: boolean = false;
  private sessionTimeout: null | ReturnType<typeof setTimeout> = null;
  private sessionLoading: boolean = false;
  private user: IUser;

  private api: ApisauceInstance;

  constructor({ baseURL }: IHttpServiceParams) {
    this.api = create({ baseURL });
  }

  initSessionTimeout = (accessTokenExp: number): void => {
    const currentTimestampInSeconds = Math.round(Date.now() / 1000);
    const accessTokenValidForNextSeconds = accessTokenExp - currentTimestampInSeconds;
    const scheduleRefreshAfterSeconds = accessTokenValidForNextSeconds - 5 * 60; // refresh 5 mins before expiration

    console.info('Session valid for the next ', (accessTokenValidForNextSeconds / 60 / 60).toFixed(2), ' hours');
    if (this.sessionTimeout) {
      clearTimeout(this.sessionTimeout);
    }
    this.sessionTimeout = setTimeout(() => {
      // eslint-disable-next-line no-console
      console.log('Session is about to expire - refreshing');
      this.refreshSession();
    }, scheduleRefreshAfterSeconds * 1000);
  };

  @action
  setUser = (user: IUser): void => {
    this.user = user;
  };

  getSession = (): Promise<ISessionInfo> => new Promise((resolve: (info: ISessionInfo) => void): void => {
    if (!this.sessionLoading) {
      resolve(this.readSession());
    } else {
      setTimeout(async (): Promise<void> => {
        // eslint-disable-next-line no-console
        console.log('Waiting for session to be refreshed');
        resolve(await this.getSession());
      }, 1000);
    }
  });

  @action
  refreshSession = async (): Promise<ISessionInfo> => {
    const { accessToken } = this.readSession();
    const requestConfig = {
      headers: { Authorization: `Bearer ${accessToken.value}` },
      refreshAccessToken: false, // to avoid infinite recursion
    };
    this.sessionLoading = true;

    return this.api.post<ISessionInfo>('/sessions/current', undefined, requestConfig)
      .then((response: ApiResponse<ISessionInfo>) => {
        if (response.ok && response.data) {
          this.sessionLoading = false;
          this.updateToken(response.data.accessToken, this.rememberMe);
        } else if (response.problem) {
          console.error(
            'Failed to refresh current session. Problem code: '
            + `${response.problem}; ${response.originalError.code} ${response.originalError.response?.statusText}`
          );
          throw new Error('Failed to refresh current session');
        }
        return response.data as ISessionInfo;
      });
  };

  updateToken(token: IAccessToken | null, rememberMe: boolean): void {
    this.accessToken = token?.value ?? null;
    this.accessTokenExp = token?.expiration ?? null;
    this.rememberMe = rememberMe;
    if (rememberMe) {
      secureStorage.set(ACCESS_TOKEN_KEY, this.accessToken);
      secureStorage.set(ACCESS_TOKEN_EXP_KEY, this.accessTokenExp?.toString() ?? null);
    }
  }

  /**
   * Reads session info from local storage, or if not available, then from current in-memory state
   */
  @action
  private readSession = (): ISessionInfo => {
    const accessToken = secureStorage.get(ACCESS_TOKEN_KEY) || this.accessToken;
    const accessTokenExp = secureStorage.get(ACCESS_TOKEN_EXP_KEY) || this.accessTokenExp;
    const user = secureStorage.get(USER_DATA_KEY) as IUser || this.user;
    const account = user?.account;
    return {
      accessToken: {
        value: accessToken,
        expiration: accessTokenExp
      },
      user,
      account
    };
  };
}

export default new SessionService({
  baseURL: config.api.host
});
