import { default as getInitials } from 'initials';
import type { ReactElement } from 'react';
import React, {
  useEffect, useState
} from 'react';
import {
  Container, Image
} from './Avatar.styles';

export type TAvatarSize = 'small' | 'large';

export interface IAvatarProps {
  size: TAvatarSize;
  name: string;
  image?: string;
}

function Avatar(props: IAvatarProps): ReactElement {
  const {
    size, name, image
  } = props;
  const [userInitials, setUserInitials] = useState('');

  useEffect((): void => {
    const initials = getInitials(name);

    const initialsString = Array.isArray(initials)
      ? initials.join('')
      : initials;

    setUserInitials(initialsString);
  }, [name]);

  return (
    <Container size={size}>
      {image ? (
        <Image src={image} alt={name} />
      ) : userInitials}
    </Container>
  );
}

export default Avatar;
