import { ErrorBoundary } from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom';
import HostApp from './pages/App';
import { StoreProvider } from './stores';
import RootStore from './stores/RootStore';
import { SentrySetup } from './utils/log';

const appNode = document.getElementById('app');
const store = new RootStore();

// set up logger with Sentry DSN client key and environment
SentrySetup();

ReactDOM.render(
  <ErrorBoundary fallback={<p>An error has occurred.</p>}>
    <StoreProvider store={store}>
      <HostApp />
    </StoreProvider>
  </ErrorBoundary>,
  appNode
);
