import { observer } from 'mobx-react-lite';
import type {
  FC, ReactElement
} from 'react';
import React, {
  useEffect, useRef, useState
} from 'react';
import {
  useHistory, useLocation
} from 'react-router-dom';
import Avatar from '../../components/Avatar/Avatar';
import {
  HEADER_ITEM_LOGOUT, HEADER_ITEM_PREFERENCES, HEADER_ITEM_PROFILE, headerItems
} from '../../constants';
import { useStore } from '../../stores';
import { getTitle } from '../../utils/helper';
import {
  LyraH1, LyraH4, LyraIcon
} from '../Common/LyraComponents';
import {
  AvatarWrapper,
  Dropdown,
  DropdownItem,
  HeaderContainer,
  HeaderContent,
  HeaderTitle,
  UserIconContainer
} from './styles';

type HeaderPopUpItemProps = {
  key: string;
  text: string;
  icon: string;
};

const Header: FC = (): ReactElement => {
  const history = useHistory();
  const location = useLocation();
  const {
    auth, account
  } = useStore();
  const {
    isDesignCompany, user
  } = auth;
  const [modalOpen, setModalOpen] = useState(false);
  const [title, setTitle] = useState<string>('');
  const [filteredHeaderItems, setFilteredHeaderItems] = useState([] as HeaderPopUpItemProps[]);

  useEffect((): void => {
    setTitle(getTitle(location.pathname));
  }, [location.pathname]);

  const handleClick = (key: string): void => {
    if (key === HEADER_ITEM_PROFILE) {
      setModalOpen(false);
      history.push('/profile');
    } else if (key === HEADER_ITEM_PREFERENCES) {
      setModalOpen(false);
      history.push('/preferences');
    } else if (key === HEADER_ITEM_LOGOUT) {
      auth.logout();
      history.push('/');
    }
  };

  const isMenuItemShown = (key: string): boolean => {
    return key != HEADER_ITEM_PREFERENCES || !isDesignCompany;
  };

  const userImage = auth.user && auth.user.picture;
  const userFullName = auth.user ? `${auth.user.firstName} ${auth.user.lastName}` : '';

  const ref = useRef<HTMLDivElement>(null);

  useEffect((): (() => void) => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (modalOpen && ref.current && !ref.current.contains(event.target as Node)) {
        setModalOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return (): void => {
      document.removeEventListener('click', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setModalOpen, modalOpen, ref.current]);

  useEffect((): void => {
    setFilteredHeaderItems(headerItems.filter((item: HeaderPopUpItemProps): boolean => isMenuItemShown(item.key)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <HeaderContainer>
      <HeaderTitle>
        <LyraH1 className="ml-xs">{title}</LyraH1>
      </HeaderTitle>
      <HeaderContent>
        <UserIconContainer aria-label={'Profile Menu'} onClick={(): void => setModalOpen(!modalOpen)}>
          <AvatarWrapper>
            <Avatar image={userImage} name={userFullName} size="small" />
          </AvatarWrapper>
          <LyraIcon
            name="arrow-down"
            style={{
              width: 20,
              height: 20
            }}
          />
        </UserIconContainer>
        {modalOpen && (
          <Dropdown ref={ref}>
            {filteredHeaderItems.map((item: HeaderPopUpItemProps) => {
              return (
                <DropdownItem key={item.text} data-tag={item.text} onClick={(): void => handleClick(item.key)}>
                  <LyraIcon
                    name={item.icon}
                    style={{
                      width: 20,
                      height: 20,
                      marginLeft: 15,
                      marginRight: 15
                    }}
                  />
                  <LyraH4>{item.text}</LyraH4>
                </DropdownItem>
              );
            })}
          </Dropdown>
        )}
      </HeaderContent>
    </HeaderContainer>
  );
};

export default observer(Header);
