// local storage data keys
export const ACCESS_TOKEN_KEY = 'LYRA_ACCESS_TOKEN';
export const ACCESS_TOKEN_EXP_KEY = 'LYRA_ACCESS_TOKEN_EXP';
export const USER_DATA_KEY = 'LYRA_USER_DATA';
export const USER_DATA_SECRET = 'LYRA_THE_BEST';
export const LEAD_DATA_KEY = 'LYRA_LEAD_DATA';

/**
 * Name of a custom claim within access token that holds info about Lyra Account
 */
export const LYRA_ACCOUNT_KEY = 'https://lyrasolar.com/acc';
