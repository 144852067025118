import type { PropsTheme } from 'styled-components';
import styled from 'styled-components';
import type { Color } from '../../typings';
import { LyraFlexbox } from '../Common/LyraComponents';

export const HeaderContainer = styled.div`
  background: ${({ theme }: PropsTheme): Color => theme.colors.highlight};
  padding-left: 16px;
  padding-right: 16px;
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const AvatarWrapper = styled.div`
  margin-right: 10px;
`;

export const HeaderTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 85%;
  height: 100%;
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  width: 15%;
  height: 100%;
`;

export const UserIconContainer = styled(LyraFlexbox).attrs({
  justify: 'flex-end',
  align: 'center',
  stretch: 'auto'
})`
  cursor: pointer;
`;

export const Dropdown = styled.div`
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-color: ${({ theme }: PropsTheme): Color => theme.colors.highlight};
  position: absolute;
  top: 60px;
  z-index: 100;
  border-radius: 5px;
  padding: 10px;
  right: 0.5%;
`;

export const DropdownItem = styled(LyraFlexbox).attrs({
  justify: 'flex-start',
  align: 'center',
  position: 'absolute'
})`
  width: 170px;
  height: 50px;
  cursor: pointer;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;
