import type {
  AnalyticsEvent, IAnalytics
} from '@aurorasolar/lyra-design-tool';
import config from '../../config/config';
import hubSpot from '../hubspot/HubSpotTracking';
import { canUseDOM } from '../../utils/helper';
import { cookies } from '../../utils/storage';
import type { CrmIdentityExpanded } from '../../utils/analytics';
import AnalyticsJS = SegmentAnalytics.AnalyticsJS;

const segmentSession = !!cookies.get('ajs_anonymous_id') || !!localStorage.getItem('ajs_anonymous_id');
const isSegmentActive = (): boolean => {
  return config.analytics.enabled && config.analytics.provider === 'segment' && canUseDOM() && !!window.analytics;
};

declare global {
  interface Window {
    analytics: AnalyticsJS;
  }
}

window.analytics = window.analytics || {};

class AnalyticsService implements IAnalytics {
  /**
   * See Segment: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/
   */
  identify(traits?: CrmIdentityExpanded): void {
    if (isSegmentActive() && segmentSession) {
      window.analytics.identify(traits);
    } else {
      // Identify with HubSpot directly if Segment is not active (e.g., it is blocked by browser's tracking protection)
      hubSpot.identify(traits);
    }
  }

  group(groupId: string, traits?: object): void {
    if (isSegmentActive()) {
      window.analytics.group(groupId, traits);
    }
  }

  /**
   * This method is used to track events from both Host App (via direct calls)
   * and from Design Tool (via IAnalytics interface).
   *
   * See Segment: https://segment.com/docs/connections/spec/track/
   */
  trackEvent(event: AnalyticsEvent): void {
    if (isSegmentActive()) {
      window.analytics.track(event.name, event.properties);
    } else {
      hubSpot.track(event.name, event.properties);
    }
  }

  /*
   Default page properties:
    {
      title: 'Page title',
      url: 'https://example.com/example',
      path: '/example',
      referrer: 'https://example.com/example-referrer'
    }
   See: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#default-page-properties
  */
  page(category: string, name: string, properties?: object): void {
    if (isSegmentActive()) {
      window.analytics.page(category, name, properties);
    } else {
      hubSpot.page();
    }
  }

  reset(): void {
    if (isSegmentActive()) {
      window.analytics.reset();
    }
  }

  /*
   * Note: the actual type of `leadStore` is always `LeadStore`.
   * `any` was used here to avoid pre-commit hook failure due to dependency cycle, when there was no time to fix it
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  analyticsSessionId(leadStore?: any): string {
    return hubSpot.analyticsSessionId(leadStore);
  }

  analyticsUserToken(): string | undefined {
    return config.hubSpot.enabled ? hubSpot.analyticsUserToken() : undefined;
  }
}

const AnalyticsServiceInstance = new AnalyticsService();

export default AnalyticsServiceInstance;
