import { observer } from 'mobx-react-lite';
import type {
  ReactElement, ReactNode
} from 'react';
import React from 'react';
import type { RouteProps } from 'react-router-dom';
import {
  Redirect, Route, useLocation
} from 'react-router-dom';
import { useStore } from '../../stores';

interface IPrivateRouteProps extends RouteProps {
  component: React.ComponentClass | React.FC;
}

const PrivateRoute: React.FC<IPrivateRouteProps> = (props: IPrivateRouteProps): ReactElement => {
  const {
    component: Component, ...rest
  } = props;
  const { auth } = useStore();
  const location = useLocation();

  const renderComponent = (routeProps: RouteProps): ReactNode => {
    if (auth.isAuthorized) {
      return <Component {...routeProps} />;
    }

    // Redirect to Login page
    // if user not authorized;
    return <Redirect to={{
      pathname: '/',
      state: { from: `${location.pathname}${location.search || ''}` }
    }} />;
  };

  return (
    <Route {...rest} render={renderComponent} />
  );
};

export default observer(PrivateRoute);
