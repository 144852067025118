import config from '../../config/config';
import { canUseDOM } from '../../utils/helper';

/**
 * A function that dynamically loads an external HubSpot script
 */
const loadHubSpotScriptIfNeeded = (): void => {
  const hubSpotScriptElementId = 'hs-script-loader';

  // If HubSpot is not enabled, or we can't use DOM, then no need to do anything
  if (!config.hubSpot.enabled || !canUseDOM()) {
    // eslint-disable-next-line no-console
    console.log(`HubSpot enabled: ${config.hubSpot.enabled}, DOM can be used ${canUseDOM()}`);
    return;
  }
  if (window.document.getElementById(hubSpotScriptElementId)) {
    // eslint-disable-next-line no-console
    console.log('HubSpot script is already present in DOM');
    return;
  }

  /*
   * HubSpot docs suggest loading the script like this:
   *
   * <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/{hubId}.js">
   * </script>
   *
   * We do it the same way, except we create it dynamically, and then insert it next to the other scripts on the page
   */
  const hubSpotLoadingScript = window.document.createElement('script');
  hubSpotLoadingScript.type = 'text/javascript';
  hubSpotLoadingScript.async = true;
  hubSpotLoadingScript.defer = true;
  hubSpotLoadingScript.src = `//js.hs-scripts.com/${config.hubSpot.hubId}.js`;

  const firstScript = window.document.getElementsByTagName('script')[0];
  firstScript.parentNode!.insertBefore(hubSpotLoadingScript, firstScript);

  // eslint-disable-next-line no-console
  console.log('HubSpot script inserted into DOM');
};

export default loadHubSpotScriptIfNeeded;
