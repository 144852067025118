import type { Libraries } from '@react-google-maps/api/dist/utils/make-load-script-url';

export const DROPDOWN_PORTAL_ID = 'lyra-host-dropdown-portal';

export const HEADER_ITEM_PREFERENCES: string = 'preferences';
export const HEADER_ITEM_PROFILE: string = 'profile';
export const HEADER_ITEM_LOGOUT: string = 'logout';

export const headerItems = [
  {
    key: HEADER_ITEM_PREFERENCES,
    text: 'Preferences',
    icon: 'icon-settings',
  },
  {
    key: HEADER_ITEM_PROFILE,
    text: 'Profile',
    icon: 'icon-user',
  },
  {
    key: HEADER_ITEM_LOGOUT,
    text: 'Logout',
    icon: 'icon-logout',
  }
];

export const GOOGLE_MAP_LIBRARIES: Libraries = ['places'];

export const userRoles = [
  {
    label: 'Super Admin',
    value: 'super-admin'
  },
  {
    label: 'Admin',
    value: 'admin'
  },
  {
    label: 'User',
    value: 'user'
  },
  {
    label: 'Guest',
    value: 'guest'
  }
];

export const PRIMARY_USE_OPTIONS = [
  {
    label: 'Permit packages',
    value: 'PERMIT_PACKAGES'
  },
  {
    label: 'Preliminary designs for proposals',
    value: 'PROPOSALS'
  }
];

export const ACCOUNT_TYPES = [
  {
    label: 'Installation Company',
    value: 'INSTALLATION_COMPANY'
  },
  {
    label: 'Homeowner',
    value: 'HOMEOWNER'
  },
];

export const states = [
  {
    value: 'AK',
    label: 'Alaska'
  },
  {
    value: 'AL',
    label: 'Alabama'
  },
  {
    value: 'AR',
    label: 'Arkansas'
  },
  {
    value: 'AZ',
    label: 'Arizona'
  },
  {
    value: 'CA',
    label: 'California'
  },
  {
    value: 'CO',
    label: 'Colorado'
  },
  {
    value: 'CT',
    label: 'Connecticut'
  },
  {
    value: 'DC',
    label: 'District of Columbia'
  },
  {
    value: 'DE',
    label: 'Delaware'
  },
  {
    value: 'FL',
    label: 'Florida'
  },
  {
    value: 'GA',
    label: 'Georgia'
  },
  {
    value: 'HI',
    label: 'Hawaii'
  },
  {
    value: 'IA',
    label: 'Iowa'
  },
  {
    value: 'ID',
    label: 'Idaho'
  },
  {
    value: 'IL',
    label: 'Illinois'
  },
  {
    value: 'IN',
    label: 'Indiana'
  },
  {
    value: 'KS',
    label: 'Kansas'
  },
  {
    value: 'KY',
    label: 'Kentucky'
  },
  {
    value: 'LA',
    label: 'Louisiana'
  },
  {
    value: 'MA',
    label: 'Massachusetts'
  },
  {
    value: 'MD',
    label: 'Maryland'
  },
  {
    value: 'ME',
    label: 'Maine'
  },
  {
    value: 'MI',
    label: 'Michigan'
  },
  {
    value: 'MN',
    label: 'Minnesota'
  },
  {
    value: 'MO',
    label: 'Missouri'
  },
  {
    value: 'MS',
    label: 'Mississippi'
  },
  {
    value: 'MT',
    label: 'Montana'
  },
  {
    value: 'NC',
    label: 'North Carolina'
  },
  {
    value: 'ND',
    label: 'North Dakota'
  },
  {
    value: 'NE',
    label: 'Nebraska'
  },
  {
    value: 'NH',
    label: 'New Hampshire'
  },
  {
    value: 'NJ',
    label: 'New Jersey'
  },
  {
    value: 'NM',
    label: 'New Mexico'
  },
  {
    value: 'NV',
    label: 'Nevada'
  },
  {
    value: 'NY',
    label: 'New York'
  },
  {
    value: 'OH',
    label: 'Ohio'
  },
  {
    value: 'OK',
    label: 'Oklahoma'
  },
  {
    value: 'OR',
    label: 'Oregon'
  },
  {
    value: 'PA',
    label: 'Pennsylvania'
  },
  {
    value: 'RI',
    label: 'Rhode Island'
  },
  {
    value: 'SC',
    label: 'South Carolina'
  },
  {
    value: 'SD',
    label: 'South Dakota'
  },
  {
    value: 'TN',
    label: 'Tennessee'
  },
  {
    value: 'TX',
    label: 'Texas'
  },
  {
    value: 'UT',
    label: 'Utah'
  },
  {
    value: 'VA',
    label: 'Virginia'
  },
  {
    value: 'VT',
    label: 'Vermont'
  },
  {
    value: 'WA',
    label: 'Washington'
  },
  {
    value: 'WI',
    label: 'Wisconsin'
  },
  {
    value: 'WV',
    label: 'West Virginia'
  },
  {
    value: 'WY',
    label: 'Wyoming'
  }
];

export const countries = [
  {
    label: 'United States',
    value: 'US'
  },
  {
    label: 'Canada',
    value: 'CA'
  }
];

export const MONTHS = [
  {
    label: 'JANUARY',
    value: '01'
  },
  {
    label: 'FEBRUARY',
    value: '02'
  },
  {
    label: 'MARCH',
    value: '03'
  },
  {
    label: 'APRIL',
    value: '04'
  },
  {
    label: 'MAY',
    value: '05'
  },
  {
    label: 'JUNE',
    value: '06'
  },
  {
    label: 'JULY',
    value: '07'
  },
  {
    label: 'AUGUST',
    value: '08'
  },
  {
    label: 'SEPTEMBER',
    value: '09'
  },
  {
    label: 'OCTOBER',
    value: '10'
  },
  {
    label: 'NOVEMBER',
    value: '11'
  },
  {
    label: 'DECEMBER',
    value: '12'
  }
];

export const YEARS = [
  {
    label: '2020',
    value: '2020'
  },
  {
    label: '2021',
    value: '2021'
  },
  {
    label: '2022',
    value: '2022'
  },
  {
    label: '2023',
    value: '2023'
  },
  {
    label: '2024',
    value: '2024'
  },
  {
    label: '2025',
    value: '2025'
  },
  {
    label: '2026',
    value: '2026'
  },
  {
    label: '2027',
    value: '2027'
  },
  {
    label: '2028',
    value: '2028'
  },
  {
    label: '2029',
    value: '2029'
  },
  {
    label: '2030',
    value: '2030'
  },
  {
    label: '2031',
    value: '2031'
  },
  {
    label: '2032',
    value: '2032'
  },
  {
    label: '2033',
    value: '2033'
  },
  {
    label: '2034',
    value: '2034'
  },
  {
    label: '2035',
    value: '2035'
  },
  {
    label: '2035',
    value: '2035'
  }
];

export const DEFAULT_DC_TERMINAL_TEMPRATURE_RATING = [
  {
    label: '75°C',
    value: '75'
  },
  {
    label: '60°C',
    value: '60'
  }
];

export const DEFAULT_AC_TERMINAL_TEMPERATURE_RATING = [
  {
    label: '75°C',
    value: '75'
  },
  {
    label: '60°C',
    value: '60'
  }
];

export const DEFAULT_CONDUCTOR_TYPE = [
  {
    value: 'UL_RHW_2',
    label: 'RHW-2'
  },
  {
    value: 'UL_THWN_2',
    label: 'THWN-2'
  },
  {
    value: 'UL_XHHW_2',
    label: 'XHHW-2'
  }
];

export const CONDUIT_TYPE_FOR_INTERIOR_APPLICATIONS = [
  {
    value: 'EMT',
    label: 'EMT'
  },
  {
    value: 'RMC',
    label: 'Rigid Steel'
  },
  {
    value: 'GRC',
    label: 'Galvanized Rigid Steel'
  },
  {
    value: 'FMC',
    label: 'Flexible Steel'
  },
  {
    value: 'LFMC',
    label: 'LFMC'
  }
];

export const CONDUIT_TYPE_FOR_EXTERIOR_APPLICATIONS = [
  {
    value: 'EMT',
    label: 'EMT'
  },
  {
    value: 'RMC',
    label: 'Rigid Steel'
  },
  {
    value: 'FMC',
    label: 'Flexible Steel'
  },
  {
    value: 'LFMC',
    label: 'LFMC'
  },
  {
    value: 'LFNC',
    label: 'LFNC'
  },
  {
    value: 'PVC_40',
    label: 'PVC-40'
  },
  {
    value: 'PVC_80',
    label: 'PVC-80'
  }
];

export const MIN_CONDUTOR_SIZE_FOR_PV_SOURCE_CIRCUITS = [
  {
    value: 'WG_12_AWG',
    label: '12 AWG'
  },
  {
    value: 'WG_10_AWG',
    label: '10 AWG'
  },
  {
    value: 'WG_8_AWG',
    label: '8 AWG'
  }
];

export const MIN_CONDUCTOR_SIZE_FOR_AC_BRANCH_SOURCE_CIRCUITS = [
  {
    value: 'WG_12_AWG',
    label: '12 AWG'
  },
  {
    value: 'WG_10_AWG',
    label: '10 AWG'
  },
  {
    value: 'WG_8_AWG',
    label: '8 AWG'
  }
];

export const MIN_OCPD_FOR_PV_SOURCE_CIRCUITS = [
  {
    value: '1',
    label: '1A'
  },
  {
    value: '2',
    label: '2A'
  },
  {
    value: '3',
    label: '3A'
  },
  {
    value: '4',
    label: '4A'
  },
  {
    value: '5',
    label: '5A'
  },
  {
    value: '6',
    label: '6A'
  },
  {
    value: '7',
    label: '7A'
  },
  {
    value: '8',
    label: '8A'
  },
  {
    value: '9',
    label: '9A'
  },
  {
    value: '10',
    label: '10A'
  },
  {
    value: '15',
    label: '15A'
  },
  {
    value: '20',
    label: '20A'
  }
];

export const MIN_VOLTAGE_DROP_IN_PERCENT = 0.5;
export const MAX_VOLTAGE_DROP_IN_PERCENT = 5;

export const WATERMARK = [
  {
    value: 'NONE',
    label: 'None'
  },
  {
    value: 'NOT_FOR_CONSTRUCTION',
    label: 'Not For Construction'
  },
  {
    value: 'FOR_QUOTING_ONLY',
    label: 'For Quoting Only'
  },
  {
    value: 'DRAFT_PRINT',
    label: 'Draft Print'
  },
  {
    value: 'PRELIMINARY',
    label: 'Preliminary'
  }
];

export const MAP_STYLE = [
  {
    value: 'NONE',
    label: 'None'
  },
  {
    value: 'AERIAL_MAP',
    label: 'Aerial Image'
  },
  {
    value: 'ROAD_MAP',
    label: 'Street Map'
  }
];

export const MESSAGE_BACKGROUND_COLOR = {
  success: 'rgba(31, 192, 192, 0.25)',
  error: 'rgba(255, 115, 110, 0.25)',
  warning: 'rgb(255,173,110, 0.25)',
  info: 'rgb(110,163,255, 0.25)'
};

export const MESSAGE_BORDER_COLOR = {
  success: '#1fc0c0',
  error: '#ff736e',
  warning: '#ffad6e',
  info: '#6ec5ff'
};
