import type {
  FC, ReactElement
} from 'react';
import React, { useContext } from 'react';
import PulseLoader from 'react-spinners/PulseLoader';
import styled, { ThemeContext } from 'styled-components';
import { EmptyCardWrapper } from '../../components/Common/styles';

const LoadingContainer = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100vh;
`;

export const EmbeddableLoadingIndicator = (): ReactElement => {
  const theme = useContext(ThemeContext);
  return <PulseLoader size={15} color={theme!.colors.mainColor1 as string} loading={true} data-testid="loader" />;
};

// eslint-disable-next-line import/no-unused-modules
export const EmbeddableLoadingIndicatorWrapper = styled.div`
  //Maybe delete?
  margin: 40px;
`;

type LoadingElementProps = {
  loading: boolean;
  children: React.ReactNode;
};

export const LoadingCard: FC<LoadingElementProps> = ({
  loading, children
}: LoadingElementProps): ReactElement => {
  const loadingIndicator = (
    <EmptyCardWrapper>
      <EmbeddableLoadingIndicator />
    </EmptyCardWrapper>
  );
  return loading ? loadingIndicator : <>{children}</>;
};

export const LoadingMask: FC<LoadingElementProps> = ({
  loading, children
}: LoadingElementProps): ReactElement => {
  return loading ? <EmbeddableLoadingIndicator /> : <>{children}</>;
};

export const LoadingIndicator = (): ReactElement => {
  const theme = useContext(ThemeContext);
  return (
    <LoadingContainer>
      <PulseLoader size={15} color={theme!.colors.mainColor1 as string} loading={true} data-testid="loader" />
    </LoadingContainer>
  );
};
