import {
  LyraButtons,
  LyraCardList,
  LyraDataTable,
  LyraDropDownNew,
  LyraFontFamily,
  LyraFormElementsHostApp,
  LyraIcon,
  LyraLayout,
  LyraSideBar,
  LyraTag,
  LyraTheme
} from '@aurorasolar/lyra-ui-kit';

const {
  Buttons, TabBar
} = LyraButtons;
const { CardList } = LyraCardList;
const { DataTable } = LyraDataTable;
const { DropDownNew } = LyraDropDownNew;
const {
  H1, H2, H3, H4, Paragraph, Link
} = LyraFontFamily.FontFamily;
const {
  HAInput,
  HADropdown,
  HACheckBox,
  HAToggle
} = LyraFormElementsHostApp.FormElementsHostApp;
const { Icon } = LyraIcon;
const { Flexbox } = LyraLayout;
const { SideBar } = LyraSideBar;
const {
  Theme, defaultTheme
} = LyraTheme;
const { Tag } = LyraTag;

export {
  Buttons as LyraButton,
  TabBar as LyraTabBar,
  CardList as LyraCardList,
  DataTable as LyraDataTable,
  DropDownNew as LyraDropDownNew,
  H1 as LyraH1,
  H2 as LyraH2,
  H3 as LyraH3,
  H4 as LyraH4,
  Paragraph as LyraParagraph,
  Link as LyraLink,
  HADropdown as LyraDropdown,
  HAInput as LyraInput,
  HACheckBox as LyraCheckBox,
  Icon as LyraIcon,
  Flexbox as LyraFlexbox,
  SideBar as LyraSideBar,
  Theme as LyraTheme,
  defaultTheme as defaultThemeConfig,
  Tag as LyraTag,
  HAToggle as LyraToggle
};
