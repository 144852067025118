import config from '../config/config';
import type {
  IProject, IProjectsResponse, ISearchParams
} from '../models';
import type { IEditProject } from '../stores/DashboardStore';
import type { IHttpServiceParams } from './generic/HttpService';
import HttpService from './generic/HttpService';

export type ProjectCreationRequest = Readonly<Pick<IProject, 'address' | 'imagery' | 'internalReferenceId' | 'customer'>> & {
  readonly account: string;
  readonly installer: string;
};

class HostAppService extends HttpService {
  constructor(params: IHttpServiceParams) {
    super(params);
  }

  /**
   * Generates a HubSpot Conversations identity verification token for a currently logged in user.
   *
   * See https://developers.hubspot.com/docs/api/conversation/visitor-identification
   */
  getHubSpotIdentityVerificationToken = async (): Promise<string> => {
    return this.get({
      url: '/sessions/current/hubspot-conversations',
      config: {
        baseURL: config.api.host!,
        headers: { Accept: 'text/plain' }
      }
    });
  };

  createProject = async (data: ProjectCreationRequest): Promise<IProject> => {
    const project = await this.post<ProjectCreationRequest, IProject>({
      url: '/projects',
      data
    });
    window.dispatchEvent(new CustomEvent('LyraProjectCreated'));
    return project;
  };

  editProject = (project: IEditProject): Promise<void> => {
    const {
      id, ...rest
    } = project;
    if (rest.customer && rest.customer.firstName === '') {
      delete rest.customer.firstName;
    }
    return this.patch<IEditProject, void>({
      url: `/projects/${id}`,
      data: rest
    });
  };

  deleteProject = (projectId: string): Promise<void> => {
    return this.delete({
      url: `/projects/${projectId}`,
    });
  };

  getProject = (projectId: string, showErrorsToUser: boolean = true): Promise<IProject> => {
    return this.get({
      url: `/projects/${projectId}`,
      showErrorsToUser
    });
  };

  getProjectsData = (accountId: string, params: ISearchParams): Promise<IProjectsResponse> => {
    return this.get({
      url: `/accounts/${accountId}/projects`,
      data: params
    });
  };
}

export default new HostAppService({
  baseURL: config.api.host
});
