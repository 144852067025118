import type {
  FC, ReactElement, ReactNode
} from 'react';
import React from 'react';
import Modal from 'react-modal';

export const MODAL_PORTAL_ID = 'lyra-host-modal-portal';

type ModalProps = {
  isOpen: boolean;
  onRequestClose?: () => void;
  children: string | ReactNode;
  contentStyle: React.CSSProperties;
};

const ModalWrapper: FC<ModalProps> = ({
  isOpen,
  onRequestClose,
  children,
  contentStyle,
}: ModalProps): ReactElement => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          zIndex: 999,
        },
        content: {
          position: 'absolute',
          border: '1px solid #ccc',
          background: '#fff',
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          borderRadius: '8px',
          outline: 'none',
          padding: '40px 60px 40px 60px',
          zIndex: 1000,
          ...contentStyle,
        },
      }}
      ariaHideApp={false}
      parentSelector={(): HTMLElement => document.getElementById(MODAL_PORTAL_ID) ?? document.body}
    >
      {children}
    </Modal>
  );
};

export { ModalWrapper };
