import AccountStore from './AccountStore';
import AuthStore from './AuthStore';
import DashboardStore from './DashboardStore';
import LeadStore from './LeadStore';

export default class RootStore {
  account = new AccountStore();
  auth = new AuthStore(this);
  dashboard = new DashboardStore(this);
  lead = new LeadStore();
}
