import config from '../config/config';
import type {
  IOrderedProductsResponse, ISearchParams
} from '../models';
import type {
  IBillingConfigurationResponse, IInvoiceConfiguration
} from '../models/IBillingConfigurationResponse';
import type { IHttpServiceParams } from './generic/HttpService';
import HttpService from './generic/HttpService';

const baseURL = config.api.documents;

export interface IStripeSetupIntentData {
  readonly clientSecret: string;
}

class DocumentsService extends HttpService {
  constructor(params: IHttpServiceParams) {
    super(params);
  }

  getBillingInfo = (accountId: string): Promise<IBillingConfigurationResponse> => {
    return this.get({
      url: `/accounts/${accountId}/billing/configuration`
    });
  };

  changeInvoiceConfiguration = (accountId: string, invoiceConfiguration: IInvoiceConfiguration): Promise<void> => {
    return this.put({
      url: `/accounts/${accountId}/billing/configuration/invoice`,
      data: invoiceConfiguration
    });
  };

  getOrderedProductsData = (accountId: string, params: ISearchParams): Promise<IOrderedProductsResponse> => {
    return this.get({
      url: `/accounts/${accountId}/billing/ordered-products`,
      data: params
    });
  };

  createSetupIntent = (accountId: string): Promise<IStripeSetupIntentData> => {
    return this.post({
      url: `/accounts/${accountId}/billing/configuration/stripe/setup-intent`
    });
  };

  updateLogo = (accountId: string, data: File): Promise<void> => {
    return this.put({
      url: `/installers/${accountId}/logo`,
      data,
      config: {
        baseURL,
        headers: {
          'Content-Type': data.type,
        }
      }
    });
  };

  deleteLogo = (accountId: string): Promise<void> => {
    return this.delete({
      url: `/installers/${accountId}/logo`,
    });
  };

  getInstallerLogoUrl = (accountId: string, installerId: string): string => {
    return `${baseURL}${this.getRelativeInstallerLogoUrl(accountId, installerId)}`;
  };

  getLogo = (accountId: string, installerId: string): Promise<string> => {
    return this.get({
      url: this.getRelativeInstallerLogoUrl(accountId, installerId),
      config: {
        baseURL,
        headers: {
          Accept: 'image/*',
        }
      },
      showErrorsToUser: false
    });
  };

  private getRelativeInstallerLogoUrl = (accountId: string, installerId: string): string => {
    // LYRA-5628: Despite backend sending Cache-Control: no-store header, some browsers (at least Firefox)
    // still load the cached version for <img> tags (from short-lived in-memory cache).
    // A workaround for this is to always add a changing query parameter
    const cacheBuster = `c=${Date.now()}`;
    return `/accounts/${accountId}/installers/${installerId}/logo?${cacheBuster}`;
  };
}

export default new DocumentsService({
  baseURL,
});
