import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  max-width: 400px;
  margin: 0 auto;
`;

export const Image = styled.img`
  margin-bottom: 32px;
`;

export const Text = styled.p`
  font-family: 'Mukta', sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #100E2A;
  margin-bottom: 20px;
`;

export const Title = styled(Text)`
  font-size: 24px;
  font-weight: bold;
`;
