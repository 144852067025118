import {
  action, computed, observable
} from 'mobx';
import type {
  ICustomerFullName,
  IOrderedProduct,
  IOrderedProductsResponse,
  IProject,
  IProjectsData,
  IProjectsResponse,
  IPurchasesData,
  ISearchParams,
  ProjectAddress
} from '../models';
import DocumentsService from '../services/DocumentsService';
import type { ProjectCreationRequest } from '../services/HostAppService';
import HostAppService from '../services/HostAppService';
import type { IMobxStore } from '../typings';
import type RootStore from './RootStore';

export interface IEditProject {
  id?: string;
  internalReferenceId: string;
  customer?: ICustomerFullName;
  imagery?: {
    provider: string;
    zoomLevel: number;
  };
  address?: ProjectAddress;
}

class DashboardStore implements IMobxStore {
  @observable projectsData: IProjectsData = {
    isLoading: false,
    more: false,
    page: -1,
    projects : {}
  };
  @observable purchasesData: IPurchasesData = {
    isLoading: false,
    more: false,
    page: -1,
    purchases : []
  };

  @observable searchAccountId: string = '';
  @observable searchQuery: string = '';
  @observable searchStartDate: Date | null = null;
  @observable searchEndDate: Date | null = null;
  @observable filter: string = '';
  @observable isLoading: boolean = false;
  @observable error: string | null = null;
  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  /**
   * Determines the Account ID to be used when loading projects.
   */
  @computed get effectiveAccountId(): string {
    if (this.rootStore.auth.isApplicationAdmin
      && this.searchAccountId?.match(/^([0-9a-f]{8})-(([0-9a-f]{4}\-){3})([0-9a-f]{12})$/i)
    ) {
      return this.searchAccountId;
    }
    return this.rootStore.auth.accountId;
  }

  @computed
  get isEmptyFilter(): boolean {
    return this.filter === ''
      && this.searchStartDate === null
      && this.searchEndDate === null;
  }

  @computed get userHasProjects(): boolean {
    return !!Object.keys(this.projectsData.projects).length;
  }

  @computed get userHasPurchases(): boolean {
    return !!this.purchasesData.purchases.length;
  }

  @computed get isProjectsListLoading(): boolean {
    return this.isLoading || this.projectsData.isLoading;
  }

  @computed get isPurchasesListLoading(): boolean {
    return this.isLoading || this.purchasesData.isLoading;
  }

  @action setFilter = (newFilter: string): void => {
    this.filter = newFilter;
  };

  @action checkIfDashboardLoading = (): void => {
    this.setIsDashboardDataLoading(this.projectsData.isLoading || this.purchasesData.isLoading);
  };

  @action setIsDashboardDataLoading = (flag: boolean): void => {
    this.isLoading = flag;
  };

  @action setIsProjectsDataLoading = (flag: boolean): void => {
    this.projectsData.isLoading = flag;
  };

  @action setIsPurchasesDataLoading = (flag: boolean): void => {
    this.purchasesData.isLoading = flag;
  };

  @action createProject = (project: ProjectCreationRequest): Promise<IProject> => {
    return HostAppService.createProject(project);
  };

  @action editProject = (project: IEditProject): Promise<void> => {
    return HostAppService.editProject(project);
  };

  @action deleteProject = (projectId: string): Promise<void> => {
    return HostAppService.deleteProject(projectId);
  };

  @action resetDashboardData = (): void => {
    this.isLoading = false;
    this.projectsData = {
      isLoading: false,
      more: false,
      page: -1,
      projects : {}
    };
    this.purchasesData = {
      isLoading: false,
      more: false,
      page: -1,
      purchases: []
    };
  };

  @action refreshDashboardData = (): void => {
    this.resetDashboardData();
    this.setIsDashboardDataLoading(true);
    this.loadMoreProjectsData(true);
    this.loadMorePurchasesData(true);
  };

  @action loadMoreProjectsData = (isDashboardLoading?: boolean): void => {
    this.setIsProjectsDataLoading(true);
    this.projectsData.page = this.projectsData.page + 1;
    const params: ISearchParams = this.getSearchParameters(this.projectsData.page);
    this.filter = params.query || '';

    HostAppService.getProjectsData(this.effectiveAccountId, params)
      .then(this.normalizeProjectsResponse.bind(this))
      .finally(() => {
        this.setIsProjectsDataLoading(false);
        if (isDashboardLoading) {
          this.checkIfDashboardLoading();
        }
      });
  };

  @action loadMorePurchasesData = (isDashboardLoading?: boolean): void => {
    this.setIsPurchasesDataLoading(true);
    this.purchasesData.page = this.purchasesData.page + 1;
    const params: ISearchParams = this.getSearchParameters(this.purchasesData.page);
    this.filter = params.query || '';

    DocumentsService.getOrderedProductsData(this.effectiveAccountId, params)
      .then(this.loadPurchasesFromOrderedProductsResponse.bind(this))
      .finally(() => {
        this.setIsPurchasesDataLoading(false);
        if (isDashboardLoading) {
          this.checkIfDashboardLoading();
        }
      });
  };

  private normalizeProjectsResponse = (response: IProjectsResponse): void => {
    response.projects.forEach(
      (project: IProject) => this.projectsData.projects[project.id as string] = project
    );
    this.projectsData.more = response.more;
  };

  private loadPurchasesFromOrderedProductsResponse = (response: IOrderedProductsResponse): void => {
    response.orderedProducts.forEach(
      (orderedProduct: IOrderedProduct) => {
        this.purchasesData.purchases.push({
          id: orderedProduct.productId,
          name: orderedProduct.description[1],
          billingAddress: orderedProduct.description[0],
          date: new Date(orderedProduct?.timestamp).toLocaleDateString(),
          price: orderedProduct?.value ? `$${orderedProduct?.value}` : '',
        });
      }
    );
    this.purchasesData.more = response.more;
  };

  private getSearchParameters = (selectedPage: number): ISearchParams => {
    const params: ISearchParams = {
      query: this.searchQuery,
      from: this.searchStartDate?.getTime() ?? 0,
      to: this.searchEndDate?.getTime() ?? (new Date().getTime() + 60_000), // 1 minute is added here to account for clock skew
      page: selectedPage
    };
    return params;
  };
}

export default DashboardStore;
