import config from '../config/config';
import type { IInstaller } from '../models/IInstaller';
import type { ITitleBlockOption } from '../models/Preferences';
import type { IHttpServiceParams } from './generic/HttpService';
import HttpService from './generic/HttpService';

export interface IInstallerOption {
  readonly id: string;
  readonly name: string;
}

class DesignService extends HttpService {
  constructor(params: IHttpServiceParams) {
    super(params);
  }

  getInstaller = (installerId: string): Promise<IInstaller> => {
    return this.get({
      url: `/installers/${installerId}`
    });
  };

  updateInstaller = (installerObject: IInstaller): Promise<IInstaller> => {
    return this.put({
      url: `/installers/${installerObject.id}`,
      data: installerObject
    });
  };

  getInstallerOptions = (): Promise<IInstallerOption[]> => {
    return this.get({
      url: '/accounts/current/installers'
    });
  };

  getTitleBlockOptions = (): Promise<ITitleBlockOption[]> => {
    return this.get({
      url: '/selection-options/preferences/documents/title-block'
    });
  };
}

export default new DesignService({
  baseURL: config.api.design
});
