import type {
  FC, PropsWithChildren, ReactElement
} from 'react';
import React from 'react';
import type RootStore from './RootStore';

interface IProviderProps {
  store: RootStore;
}

const StoreContext = React.createContext({});

// StoreProvider to inject root store using react context
export const StoreProvider: FC<IProviderProps> = (props: PropsWithChildren<IProviderProps>): ReactElement => {
  const {
    children, store
  } = props;
  return (
    <StoreContext.Provider value={store}>
      {children}
    </StoreContext.Provider>
  );
};

// Hook to use store in any functional component
export const useStore = (): RootStore => {
  return React.useContext(StoreContext) as RootStore;
};
