import type {
  FC, ReactElement
} from 'react';
import React from 'react';
import { useHistory } from 'react-router';
import {
  Container, Image, Text, Title
} from './NotFound.styles';

type NotFoundProps = {
  errorText: string;
};

const NotFound: FC<NotFoundProps> = ({ errorText }: NotFoundProps): ReactElement => {
  const history = useHistory();
  return (
    <Container>
      <Image src="/../assets/sun-404.svg" />
      <Title>Oops! Nothing to see here.</Title>
      <Text>{errorText}</Text>
    </Container>
  );
};

export { NotFound };
