import type { IDropDownOption } from '@aurorasolar/lyra-ui-kit/lib/components/DropDownNew';
import isEmpty from 'lodash/isEmpty';
import {
  action, computed, observable
} from 'mobx';
import type {
  IBillingConfigurationResponse, IInvoiceConfiguration, IPaymentCardSaved
} from '../models/IBillingConfigurationResponse';
import type { IInstaller } from '../models/IInstaller';
import type { ITitleBlockOption } from '../models/Preferences';
import type { IInstallerOption } from '../services/DesignService';
import DesignService from '../services/DesignService';
import type { IStripeSetupIntentData } from '../services/DocumentsService';
import DocumentsService from '../services/DocumentsService';
import type { IMobxStore } from '../typings';

const addWhitespaceSuffixIfNotEmpty = (input: string): string => {
  if (input.trim()) {
    return `${input} `;
  }
  return '';
};

const addComaAndWhitespaceSuffixIfNotEmpty = (input: string): string => {
  if (input.trim()) {
    return `${input}, `;
  }
  return '';
};

class AccountStore implements IMobxStore {
  isBillingLoading: boolean = false;
  isInstallerLoading: boolean = false;
  isLogoLoading: boolean = false;
  @observable currentInstaller: IInstaller = {} as IInstaller;
  @observable billingInfo: IBillingConfigurationResponse = {} as IBillingConfigurationResponse;
  @observable isLoading: boolean = false;
  @observable companyInfo: IInstaller = {} as IInstaller;
  @observable isLogoPresent: boolean = false;
  @observable installerOptions: IInstallerOption[];
  @observable titleBlockOptions: IDropDownOption[] = [];

  @computed get installerLoaded(): boolean {
    return !isEmpty(this.currentInstaller);
  }

  @computed get isBillingInfoFilled(): boolean {
    return !isEmpty(this.billingInfo);
  }

  @computed get isCompanyInfoFilled(): boolean {
    return !isEmpty(this.companyInfo);
  }

  @computed
  get isAddressPresent(): boolean {
    const { address } = this.companyInfo;

    return !!address;
  }

  @computed
  get currentInstallerId(): string {
    return this.currentInstaller.id;
  }

  @computed
  get fullCompanyAddress(): string {
    const { address } = this.companyInfo;

    if (!address) {
      return 'US';
    }

    const addressPart =
      addWhitespaceSuffixIfNotEmpty(address.addressOne ?? '')
      + (address.addressTwo ?? '');

    const cityPart = address.city ?? '';

    const statePart =
      `${addWhitespaceSuffixIfNotEmpty(address.province ?? '')
      + addWhitespaceSuffixIfNotEmpty(address.postalCode ?? '')
      }US`;

    return addComaAndWhitespaceSuffixIfNotEmpty(addressPart.trim())
      + addComaAndWhitespaceSuffixIfNotEmpty(cityPart)
      + statePart;
  }

  @computed
  get accountSingleInstallerId(): string | undefined {
    if (this.installerOptions?.length === 1) {
      return this.installerOptions[0].id;
    }
  }

  @action getInstaller = async (installerId: string): Promise<void> => {
    this.isInstallerLoading = true;
    this.isLoading = true;
    try {
      const response = await DesignService.getInstaller(installerId);
      this.currentInstaller = response;
      // FIXME: remove this after merge:
      this.companyInfo = response;

      this.isInstallerLoading = false;
      this.isLoading = this.isBillingLoading || this.isLogoLoading || this.isInstallerLoading;
    } catch (e) {
      this.isInstallerLoading = false;
      this.isLoading = this.isBillingLoading || this.isLogoLoading || this.isInstallerLoading;
    }
  };

  @action
  updateCurrentInstaller = async (installerObj: IInstaller): Promise<IInstaller> => {
    return DesignService.updateInstaller(installerObj);
  };

  @action
  checkIfLogoPresent(accountId: string, installerId: string): void {
    this.isLogoLoading = true;
    this.isLoading = true;

    DocumentsService.getLogo(accountId, installerId)
      .then((): void => {
        this.isLogoPresent = true;
      })
      .catch((): void => {
        this.isLogoPresent = false;
      })
      .finally((): void => {
        this.isLogoLoading = false;
        this.isLoading = this.isBillingLoading || this.isLogoLoading || this.isInstallerLoading;
      });
  }

  @action updateLogo = async (data: File): Promise<void> => {
    this.isLogoLoading = true;
    this.isLoading = true;
    return DocumentsService.updateLogo(this.currentInstaller.id, data)
      .then((): void => {
        this.isLogoPresent = true;
        this.isLogoLoading = false;
        this.isLoading = this.isBillingLoading || this.isLogoLoading || this.isInstallerLoading;
      });
  };

  @action deleteLogo = async (): Promise<void> => {
    return DocumentsService.deleteLogo(this.currentInstaller.id)
      .then((): void => {
        this.isLogoPresent = false;
      });
  };

  @action getBillingInfo = (accountId: string): void => {
    this.isBillingLoading = true;
    this.isLoading = true;
    DocumentsService.getBillingInfo(accountId)
      .then((billingInfo: IBillingConfigurationResponse) => {
        this.billingInfo = billingInfo;
      })
      .finally(() => {
        this.isBillingLoading = false;
        this.isLoading = this.isBillingLoading || this.isLogoLoading || this.isInstallerLoading;
      });
  };

  @action
  changeInvoiceConfiguration = async (accountId: string, invoiceConfig: IInvoiceConfiguration): Promise<void> => {
    return DocumentsService.changeInvoiceConfiguration(accountId, invoiceConfig);
  };

  @action updateCardInfo = (paymentCard: IPaymentCardSaved): void => {
    this.billingInfo.paymentCard = paymentCard;
  };

  @action createSetupIntent = async (accountId: string): Promise<IStripeSetupIntentData> => {
    return DocumentsService.createSetupIntent(accountId);
  };

  @action loadInstallerOptions = async (): Promise<IInstallerOption[]> => {
    this.installerOptions = await DesignService.getInstallerOptions();
    return this.installerOptions;
  };

  @action
  getTitleBlockOptions = async (): Promise<IDropDownOption[]> => {
    const response = await DesignService.getTitleBlockOptions();

    this.titleBlockOptions = response.map((item: ITitleBlockOption): IDropDownOption => ({
      value: item.attributes.value,
      label: item.attributes.name
    }));

    return this.titleBlockOptions;
  };
}

export default AccountStore;
