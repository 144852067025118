import {
  action, computed, observable
} from 'mobx';
import analytics from '../services/analytics';
import { LEAD_DATA_KEY } from '../constants/auth';
import type { IMobxStore } from '../typings';
import { secureStorage } from '../utils/storage';
import { LeadConvertedToUserEvent } from '../services/analytics/AnalyticsEvents';
import type { IUser } from './AuthStore';

export interface ILead {
  email?: string;
  name?: string;
  firstName?: string;
  lastName?: string;
  company?: string;
  source?: string;
  user_id?: string;
  web_key?: string;
}

class LeadStore implements IMobxStore {
  @observable lead: ILead = {} as ILead;
  @observable isLoading: boolean = false;

  getLeadData(): ILead | undefined {
    if (Object.keys(this.lead).length > 1) {
      return this.lead;
    }

    const leadData = secureStorage.get(LEAD_DATA_KEY);
    if (!leadData) {
      return;
    }

    try {
      return JSON.parse(leadData);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Could not parse LEAD_DATA_KEY data.', error);
    }
  }
  @computed
  get isOnboarding(): boolean {
    const validSource = this.lead?.source?.match(/^(hubspot|website|article|campaign)$/);
    return !!(this.lead?.email && this.lead?.user_id && !!validSource);
  }
  @computed
  get isWebLogin(): boolean {
    const validSource = this.lead?.source === 'web_nav';
    const validWebKeyRegExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    const webKey = this.lead?.web_key;
    const validWebKey = webKey && validWebKeyRegExp.test(webKey);
    return !!(validWebKey && validSource);
  }
  @action startOnboarding = (lead: ILead): void => {
    const sessionLead: ILead | undefined = this.getLeadData();
    const validNewLead = lead?.user_id ? lead : null;
    const validSessionLead = sessionLead?.user_id ? sessionLead : null;
    const validLead = validNewLead || validSessionLead;
    if (validLead) {
      this.lead = validLead;
      secureStorage.set(LEAD_DATA_KEY, JSON.stringify(validLead));
      localStorage.setItem('_crm_id', lead?.user_id || analytics.analyticsSessionId());
    }
  };
  @action getCurrentLead = (): ILead | undefined => {
    const sessionLead: ILead | undefined = this.getLeadData();
    if (sessionLead) {
      this.lead = sessionLead;
    }
    return sessionLead;
  };
  @action getLeadSession = () => {
    return analytics.analyticsSessionId();
  };
  @action convertLead = (user: IUser, identfiedCrmId: string): void => {
    const identifyCrmId = identfiedCrmId || analytics.analyticsSessionId();
    analytics.trackEvent(new LeadConvertedToUserEvent(user, identifyCrmId, this.lead));
    this.resetLeadState();
  };
  @action resetLeadState = (): void => {
    secureStorage.remove(LEAD_DATA_KEY);
    this.lead = {};
  };
}

export default LeadStore;
