import { toast } from 'react-toastify';

export function showErrorToUser(errorMessage: string): void {
  toast(errorMessage, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    type: 'error'
  });
}
