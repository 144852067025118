import styled from 'styled-components';
import type { TAvatarSize } from './Avatar';

export const Container = styled.div<{ size: TAvatarSize }>`
  background-color: #535672;
  text-transform: uppercase;
  color: white;
  font-family: 'Mukta', sans-serif;
  text-align: center;
  border-radius: 50%;
  overflow: hidden;
  
  // Styles for small avatar (right top corner)
  ${({ size }) => size === 'small' && `
    width: 40px;
    height: 40px;
    font-size: 24px;
    line-height: 40px;
  `}
  
  // Styles for large avatar (profile) 
  ${({ size }) => size === 'large' && `
    width: 84px;
    height: 84px;
    font-size: 48px;
    line-height: 84px;
  `}
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
`;
