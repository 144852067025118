import SecureLS from 'secure-ls';
import { Cookies } from 'react-cookie';
import { USER_DATA_SECRET } from '../constants/auth';

export const cookies = new Cookies();

export const secureStorage = new SecureLS({
  encodingType: 'aes',
  encryptionSecret: USER_DATA_SECRET
});
