import { HubSpotChat } from '../hubspot/HubSpotConversations';
import config from '../../config/config';

/**
 * This is an abstraction of a live-chat functionality provided by a third-party (e.g., HubSpot).
 */
class LiveChatService {
  private readonly hubSpotChat?: HubSpotChat;

  constructor() {
    if (config.hubSpot.enabled) {
      this.hubSpotChat = new HubSpotChat();
    }
  }

  /**
   * Configures the live chat widget (for an anonymous visitor) in a collapsed state (a bubble in the corner)
   */
  setUpChatWidgetForAnonymousVisitor(): void {
    this.hubSpotChat?.loadWidgetForAnonymousVisitor();
  }

  /**
   * Configures the live chat widget (for an identified user) in a collapsed state (a bubble in the corner)
   */
  setUpChatWidgetForAuthenticatedUser(userEmail: string): void {
    this.hubSpotChat?.loadWidgetForAuthenticatedUser(userEmail);
  }

  showChat(): void {
    if (this.hubSpotChat) {
      this.hubSpotChat.openChatWidget();
    } else {
      // eslint-disable-next-line no-console
      console.info('None of the live chat implementations are enabled');
    }
  }
}

export default new LiveChatService();
