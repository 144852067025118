import type {
  IUser, TAccountType, TPrimaryUse
} from '../stores/AuthStore';
import type AuthStore from '../stores/AuthStore';
import type { ILead } from '../stores/LeadStore';
import type LeadStore from '../stores/LeadStore';
import analytics from '../services/analytics';
import config from '../config/config';

// Analytics session/page and event tracking model - HubSpot CRM and Amplitude mapped
type PageTrackingExpanded = {
  userId: string;
  accountId: string;
  user_id: string;
  hostEnvironment?: string;
  hostName?: string;
  hostVersion?: string;
  designToolEnvironment?: string;
  designToolName?: string;
  designToolVersion?: string;
  isAdmin?: boolean;
  isAuthorized?: boolean;
  isInstallationCompany?: boolean;
  isLoading?: boolean;
  passResetDone?: boolean;
  signUpError?: string | null;
  updateAccountError?: string | null;
};

// HubSpot and Analytics user identity model
export type CrmIdentityExpanded = {
  usertoken?: string; // HubSpot usertoken
  userId?: string; // Segment default userId - maps to HubSpot UserID and other tools.
  id?: string; // custom attribute - mapped to Lyra (Auth0) User ID
  account_id?: string; // custom attribute - mapped to Lyra Account ID
  auth0_id?: string; // custom attribute - mapped to (Auth0) IUser ID
  user_id?: string; // custom attribute - mapped to initial HubSpot UserID (same as original MessengerId) then (Auth0) IUser id on account creation - keeps user in sync across browsers/devices
  name?: string;
  firstName?: string;
  lastName?: string;
  picture?: string;
  email?: string;
  phoneNumber?: string;
  primaryUse?: TPrimaryUse;
  accountAdmin?: boolean;
  applicationAdmin?: boolean;
  installer?: string;
  type?: TAccountType;
  source?: string; // the source of the lead or user
  'Lead Source'?: string; // the source of the lead, not always same as source
  'Company Name'?: string; // HubSpot primary attribute
};

/**
 * A standard set of proprties reported for page view events
 */
export const PageTrackingProperties = (authStore: AuthStore, leadStore: LeadStore): PageTrackingExpanded => {
  const crmId = analytics.analyticsSessionId(leadStore);
  return {
    userId: authStore.user.id, // Lyra user (Auth0) ID
    accountId: authStore.accountId, // Lyra account ID
    user_id: crmId,
    hostEnvironment: config.environment.host.environment,
    hostName: config.environment.host.name,
    hostVersion: config.environment.host.version,
    designToolEnvironment: config.environment.design.environment,
    designToolName: config.environment.design.name,
    designToolVersion: config.environment.design.version,
    isAdmin: authStore.isApplicationAdmin,
    isAuthorized: authStore.isAuthorized,
    isInstallationCompany: authStore.isInstallationCompany,
    isLoading: authStore.isLoading,
    passResetDone: authStore.passResetDone,
    signUpError: authStore.signUpError,
    updateAccountError: authStore.updateAccountError
  };
};

// Segment identify() options - HubSpot CRM mapped - accepts a lead or user and custom maps into CRM model
// https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#identify
export const CrmIdentityProperties = (user?: IUser, crmId?: string, lead?: ILead): CrmIdentityExpanded => {
  const combinedName =
    (user?.firstName || lead?.firstName) && `${user?.firstName || lead?.firstName} ${user?.lastName || lead?.lastName}`;
  return {
    usertoken: analytics.analyticsUserToken(),
    userId: lead?.user_id || crmId || user?.id, // Segment user ID, maps to HubSpot User ID (and user_id)
    id: user?.id, // Lyra User (Auth0) ID
    account_id: user?.account?.id, // Lyra user (Auth0) ID
    auth0_id: user?.id, // Lyra user (Auth0) ID
    user_id: crmId, // HubSpot User ID (and user_id)
    name: combinedName,
    firstName: user?.firstName || lead?.firstName,
    lastName: user?.lastName || lead?.lastName,
    picture: user?.picture,
    email: user?.email || lead?.email,
    phoneNumber: user?.phoneNumber,
    primaryUse: user?.primaryUse,
    accountAdmin: user?.accountAdmin,
    applicationAdmin: user?.applicationAdmin,
    installer: user?.account?.installer,
    type: user?.account?.type,
    source: lead?.source,
    'Lead Source': lead?.source,
    'Company Name': lead?.company
  };
};
