import type {
  FC, ReactElement
} from 'react';
import React, {
  useEffect, useState
} from 'react';
import { useLocation } from 'react-router-dom';
import { validRoutes } from '../../config/config';
import { useStore } from '../../stores';
import {
  AppContainer, MainWrapper
} from '../Common/styles';
import { NotFound } from '../Errors/NotFound/NotFound';
import Header from '../Header';
import { AccountSetupModal } from '../Modal/modals/AccountSetupModal/AccountSetupModal';
import { Sidebar } from '../SideBar';

type ContainerProps = {
  children: React.ReactNode;
};

const MainContainer: FC<ContainerProps> = ({ children }: ContainerProps): ReactElement => {
  const location = useLocation();
  const {
    auth: {
      user, isHomeowner, isDesignCompany
    }
  } = useStore();
  const [modalShow, setModalShow] = useState(false);

  const routeIsValid = validRoutes.some((route: RegExp) => route.test(location.pathname));
  const isNotFoundPageIsShownForHomeowner = isHomeowner && /account\/company\/edit/.test(location.pathname);

  useEffect(() => {
    if (routeIsValid) {
      setModalShow(!user?.account);
    }
  }, [user]);

  return (
    <AppContainer>
      {routeIsValid && !isNotFoundPageIsShownForHomeowner
        ? <>
          <Sidebar />
          <MainWrapper>
            <Header />
            {children}
          </MainWrapper>
          <AccountSetupModal
            modalShow={modalShow}
            setModalShow={setModalShow}
          />
        </>
        : <>
          <Sidebar />
          <MainWrapper>
            <NotFound
              errorText="The page you were looking for doesn’t exist."
            />
          </MainWrapper>
        </>
      }
    </AppContainer>
  );
};

export { MainContainer };
