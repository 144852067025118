/* eslint-disable no-console */
import hostAppService from '../HostAppService';

declare global {
  interface Window {
    /**
     * Settings that can be set (note: the type definition is not complete)
     * See: https://developers.hubspot.com/docs/api/conversation/chat-widget-sdk#configure-conversations-settings
     */
    hsConversationsSettings?: {
      loadImmediately?: boolean;
      identificationEmail?: string;
      identificationToken?: string;
    };
    /**
     * Array where callback functions can be set that get invoked once `HubSpotConversations` become available
     */
    hsConversationsOnReady: unknown[];
    /**
     * Main HubSpot Conversations API object (note: the type definition is not complete)
     */
    HubSpotConversations?: {
      resetAndReloadWidget: () => void;
      widget: {
        load: () => void;
        status: () => { loaded: boolean };
        open: () => void;
      };
    };
  }
}

window.hsConversationsSettings = {
  // We disable loading immediately, because user identity information has to be set before loading the chat widget
  loadImmediately: false
};

/**
 * An object providing a convenient higher-level API for working with HubSpot Conversations (live chat product)
 * See: https://developers.hubspot.com/docs/api/conversation/chat-widget-sdk
 */
export class HubSpotChat {
  /**
   * Property specifying whether the HubSpotConversations object is available (i.e. HubSpot script is loaded).
   */
  private isChatApiAvailable: boolean = false;
  /**
   * Identity of the current user. It can have one of three values:
   * - an email string - meaning chat is used by an authenticated user
   * - null - meaning chat is used by an anonymous visitor
   * - undefined - meaning it is not yet set
   */
  private authenticatedUserEmail: string | null | undefined = undefined;

  constructor() {
    if (window.HubSpotConversations) {
      this.isChatApiAvailable = true;
    } else {
      window.hsConversationsOnReady = [
        (): void => {
          // Clearing the list of callbacks, because loading the widget triggers them again
          window.hsConversationsOnReady = [];
          this.isChatApiAvailable = true;
          if (this.authenticatedUserEmail !== undefined) {
            // If chat widget is already configured, load it immediately
            this.loadWidget();
          }
        }
      ];
    }
  }

  /**
   * Configures and loads the chat widget for an anonymous visitor
   */
  loadWidgetForAnonymousVisitor(): void {
    this.configureAndLoadWidget(null);
  }

  /**
   * Configures the chat widget for an identified user and loads either immediately or ASAP
   */
  loadWidgetForAuthenticatedUser(userEmail: string): void {
    this.configureAndLoadWidget(userEmail);
  }

  private configureAndLoadWidget(userEmailOrNull: string | null): void {
    this.authenticatedUserEmail = userEmailOrNull;
    // If chat API is not yet available, the widget will get loaded in `hsConversationsOnReady` callback
    if (this.isChatApiAvailable) {
      this.loadWidget();
    }
  }

  /**
   * Loads or reloads the HubSpot Conversations widget configured for the current user or anonymous visitor.
   */
  private async loadWidget(): Promise<void> {
    if (!this.isChatApiAvailable) {
      console.error('Attempted to load HubSpot chat widget before HubSpotConversations API is available');
      return;
    }
    if (this.authenticatedUserEmail === undefined) {
      console.error('Attempted to load HubSpot chat widget before configuration is complete');
      return;
    }
    if (this.authenticatedUserEmail === null) {
      // Loading chat widget for an anonymous visitor
      delete window.hsConversationsSettings!.identificationEmail;
      delete window.hsConversationsSettings!.identificationToken;
      console.info('Loading HubSpot chat widget for anonymous visitor');
    } else {
      // Loading chat widget for an authenticated user
      window.hsConversationsSettings!.identificationEmail = this.authenticatedUserEmail;
      window.hsConversationsSettings!.identificationToken = await hostAppService.getHubSpotIdentityVerificationToken();
      console.info('Loading HubSpot chat widget for authenticated user');
    }
    if (this.isChatWidgetLoaded) {
      window.hsConversationsSettings!.loadImmediately = true;
      window.HubSpotConversations!.resetAndReloadWidget();
    } else {
      window.HubSpotConversations!.widget.load();
    }
  }

  /**
   * Is the chat widget iframe loaded? It has to be loaded before it can be opened.
   */
  private get isChatWidgetLoaded(): boolean {
    return this.isChatApiAvailable && (window.HubSpotConversations?.widget.status().loaded ?? false);
  }

  /**
   * Opens live chat widget
   */
  openChatWidget(): void {
    if (!this.isChatWidgetLoaded) {
      console.error('Attempted to show HubSpot chat widget when it is either not enabled or not yet loaded');
      return;
    }
    window.HubSpotConversations!.widget.open();
  }
}
