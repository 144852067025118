import {
  Col, Grid, Row
} from 'react-flexbox-grid';
import type { PropsTheme } from 'styled-components';
import styled from 'styled-components';
import type { Color } from '../../typings';
import {
  LyraCheckBox, LyraFlexbox, LyraH1, LyraH2, LyraH3, LyraH4, LyraParagraph
} from './LyraComponents';

type InputProps = PropsTheme & {
  isValid?: boolean;
  isTouched?: boolean;
  attrs?: { [attributeName: string]: string };
};

export const FlexColumnView = styled(LyraFlexbox).attrs({
  justify: 'center',
  align: 'center',
  dir: 'column'
})``;

export const AppContainer = styled.div`
  display: flex;
`;

export const ErrorLabel = styled.span`
  font: normal 12px/1.4 Mukta;
  display: inline-block;
  color: #d34a4a;
  padding: 6px 0;
`;

export const MainWrapper = styled.div`
  width: calc(100% - 272px);
  position: relative;
  left: 272px;
  overflow-x: clip;
  min-height: 100vh;
  background: ${({ theme }: PropsTheme): Color => theme.colors.grayColor11};
`;

export const ContentWrapper = styled.div`
  margin-top: 50px;
`;

export const AuthFormContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
`;

export const CenterRow = styled(Row)`
  display: flex;
  align-items: center;
`;

export const EndRow = styled(Row)`
  display: flex;
  align-items: stretch;
`;

export const SpacedRow = styled(Row)`
  display: flex;
  justify-content: space-between;

  & > div {
    flex: 1;
  }
`;

export const FormContainer = styled.div`
  position: relative;
  width: calc(100% - 680px);
  max-height: 100vh;
  padding: 161px 0px 192px 0px;
`;

export const FormOverContainer = styled.div`
  width: calc(100% - 680px);
  padding: 60px 0px 169px 0px;
`;

export const ResetContainer = styled(Grid)`
  width: calc(100% - 680px);
  padding: 161px 0px 192px 0px;
  position: relative;
`;

export const PanelHeaderText = styled.div`
  color: ${({ theme }: PropsTheme): Color => theme.colors.mainColor1};
  font: normal normal 24px Mukta;
  line-height: 1.67;
`;

export const WarningSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const PanelSubHeaderText = styled(LyraH1)`
  line-height: 1.28;
  color: ${({ theme }: PropsTheme): Color => theme.colors.grayColor1};
`;

export const ActionContainer = styled(LyraFlexbox).attrs({
  align: 'center'
})`
  margin-top: 57px;
`;

export const BlueParagraph = styled.div`
  font: bold 14px Mukta;
  margin-left: 8px;
  color: ${({ theme }: PropsTheme): Color => theme.colors.mainColor1};
  cursor: pointer;
`;

export const InputContainer = styled.div`
  margin-top: 50px;
`;

export const PaddingView = styled.div`
  margin-top: 36px;
  & > div:first-child {
    font-weight: bold;
  }
`;

export const HeightParagraph = styled(LyraParagraph)`
  line-height: 1.71;
`;

export const RightCol = styled(Col)`
  text-align: right;
`;

export const LeftCol = styled(Col)`
  display: flex;
  align-items: center;
`;

export const ButtonContainer = styled(LyraFlexbox).attrs({
  justify: 'center',
  align: 'center'
})`
  margin-top: 56px;
`;

export const ButtonWrapper = styled.div`
  margin-left: 10px;
`;

export const CommentContainer = styled.div`
  margin-top: 85px;
  text-align: center;
`;

export const CommentLink = styled.div`
  color: ${({ theme }: PropsTheme): Color => theme.colors.iconBg2};
  font: normal normal 12px Mukta;
  line-height: normal;
  text-align: left;
`;

export const InputHeaderText = styled(LyraH3)`
  font-size: 18px;
  line-height: 2.22;
`;

export const SearchWrapper = styled.div`
  position: relative;
`;

export const SearchInput = styled.input`
  width: 100%;
  height: 46px;
  border-radius: 23px;
  border: 1px solid ${({ theme }: PropsTheme): Color => theme.colors.grayColor3};
  font-family: 'Mukta', sans-serif;
  font-size: 16px;
  color: ${({ theme }: PropsTheme): Color => theme.colors.grayColor3};
  padding-left: 40px;
  outline: none;
`;

export const SearchIcon = styled.div`
  position: absolute;
  top: 18px;
  left: 15px;
  width: 18px;
  height: 18px;
  background-image: url('../../assets/search.svg');
  background-repeat: no-repeat;
`;

export const HeaderTitle = styled(LyraH2)`
  color: ${({ theme }: PropsTheme): Color => theme.colors.mainColor1};
  line-height: 1.67;
  margin-bottom: 17px;
`;

export const EmptyCardWrapper = styled(FlexColumnView)`
  width: 100%;
  height: 600px;
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-color: ${({ theme }: PropsTheme): Color => theme.colors.highlight};
  margin-bottom: 20px;
`;

export const PopUpContainer = styled(FlexColumnView)`
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-color: ${({ theme }: PropsTheme): Color => theme.colors.highlight};
  position: absolute !important;
  top: 45px;
  right: 22px;
  border-radius: 5px;
  padding: 10px 0px 10px 0px;
  z-index: 100;
  &:before {
    border: solid;
    border-color: white transparent;
    border-width: 0 0.7em 0.8em 0.7em;
    top: -8px;
    content: '';
    display: block;
    left: 13em;
    position: absolute;
    z-index: 99;
    width: 1px;
  }
`;

export const PopUpItem = styled(LyraH4)`
  width: calc(100% - 10px);
  height: 50px;
  align-items: center;
  display: flex;
  cursor: pointer;
  padding-left: 10px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;

export const CoverView = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 99;
`;

export const SmallText = styled(LyraParagraph)`
  color: ${({ theme }: PropsTheme): Color => theme.colors.mainColor6};
  line-height: 1.14;
  margin-top: 10px;
`;

export const ButtonGroup = styled(LyraFlexbox).attrs({
  justify: 'center',
  align: 'center'
})`
  width: 100%;
  height: 180px;

  & .disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.3;
  }
`;

export const ModalContainer = styled(FlexColumnView)``;

export const ConfirmText = styled(LyraH2)`
  color: ${({ theme }: PropsTheme): Color => theme.colors.mainColor1};
  line-height: 1.67;
  margin-top: 20px;
`;

export const CommentText = styled(LyraParagraph)`
  margin-top: 20px;
  color: ${({ theme }: PropsTheme): Color => theme.colors.mainColor6};
  line-height: 1.71;
  width: 312px;
  text-align: center;
`;

export const ButtonModalWrapper = styled.div`
  margin-top: 40px;
`;

export const LyraCommentText = styled(LyraParagraph)`
  color: ${({ theme }: PropsTheme): Color => theme.colors.grayColor1};
  font-weight: bold;
`;

/* FIXME: Once we use HAInput everywhere, we don't need this styles anymore */
export const LyraMainInput = styled.input.attrs<InputProps>((props: InputProps) => ({ ...props.attrs }))<InputProps>`
  width: 100%;
  height: 28px;
  font-family: 'Mukta', sans-serif;
  font-size: 14px;
  line-height: 23px;
  color: ${({
    theme, isValid, isTouched
  }: InputProps): Color =>
    !isValid && isTouched ? theme.colors.mainColor4 : theme.colors.grayColor1};
  border: none;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${({
    theme, isValid, isTouched
  }: InputProps): Color =>
    !isValid && isTouched ? theme.colors.mainColor4 : theme.colors.grayColor3};
  outline: none;
  padding: 0;
  background: inherit;
  margin-top: 10px;
  box-sizing: border-box;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  transition-property: border-bottom-color;

  &:focus {
    border-bottom-color: ${({
    theme, isValid, isTouched
  }: InputProps): Color =>
    !isValid && isTouched ? theme.colors.mainColor4 : theme.colors.mainColor1};
    border-bottom-width: 2px;
  }
`;

export const LyraMainTextarea = styled.textarea.attrs<InputProps>((props: InputProps) => ({
  ...props.attrs
}))<InputProps>`
  width: 100%;
  font-family: 'Mukta', sans-serif;
  font-size: 14px;
  line-height: 23px;
  color: ${({
    theme, isValid, isTouched
  }: InputProps): Color =>
    !isValid && isTouched ? theme.colors.mainColor4 : theme.colors.grayColor1};
  border: none;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${({
    theme, isValid, isTouched
  }: InputProps): Color =>
    !isValid && isTouched ? theme.colors.mainColor4 : theme.colors.grayColor3};
  outline: none;
  padding: 0;
  background: inherit;
  margin-top: 10px;
  box-sizing: border-box;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  transition-property: border-bottom-color;

  &:focus {
    border-bottom-color: ${({
    theme, isValid, isTouched
  }: InputProps): Color =>
    !isValid && isTouched ? theme.colors.mainColor4 : theme.colors.mainColor1};
    border-bottom-width: 2px;
  }
`;

export const LogoImage = styled.img`
  display: block;
  width: auto;
  height: auto;
  max-height: 250px;
  max-width: 100%;
  border: 1px solid ${({ theme }: PropsTheme): Color => theme.colors.grayColor3};
  border-radius: 4px;
`;

export const BoldLink = styled.span`
  color: ${({ theme }: PropsTheme): Color => theme.colors.mainColor1};
  font-weight: bold;
  cursor: pointer;
  margin-top: 15px;
  font-size: 14px;
`;

type ErrorMessage = PropsTheme & {
  isShown: boolean;
};

export const ErrorMessage = styled.div<ErrorMessage>`
  display: ${({ isShown }: ErrorMessage): string => (isShown ? 'block' : 'none')};
  width: 100%;
  margin: 8px;
  padding: 15px 12px;
  border: solid 1px #ff736e;
  background-color: rgba(255, 115, 110, 0.25);
  border-radius: 0.25rem;
  font-size: 14px;
  line-height: 18px;
`;

export const CheckBox = styled(LyraCheckBox.HACheckbox)`
  display: inline-flex;
`;
