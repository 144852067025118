import type {
  FC, ReactElement
} from 'react';
import React from 'react';
import { AlertMessageBox } from './AlertMessage.styles';

export type TAlertType = 'success' | 'error' | 'warning' | 'info';

export type AlertProps = {
  message: string;
  type?: TAlertType;
  isMessageVisible: boolean;
};

const AlertMessage: FC<AlertProps> = ({
  message,
  isMessageVisible,
  type
}: AlertProps): ReactElement => {
  return (
    <AlertMessageBox type={type!} isMessageVisible={isMessageVisible}>
      {message}
    </AlertMessageBox>
  );
};

AlertMessage.defaultProps = {
  type: 'error',
};

export { AlertMessage };
