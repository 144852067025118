import type {
  FC, ReactElement
} from 'react';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const titleBasedOnLocation = (path: string): string => {
  if (/account\/company\/edit/.test(path)) {
    return 'Company Info - Lyra Solar';
  }
  if (/account\/payment\/edit/.test(path)) {
    return 'Payment Info - Lyra Solar';
  }
  if (/account/.test(path)) {
    return 'Account - Lyra Solar';
  }
  if (/dashboard/.test(path)) {
    return 'Dashboard - Lyra Solar';
  }
  if (/forgot-password/.test(path)) {
    return 'Forgot Password - Lyra Solar';
  }
  if (/preferences/.test(path)) {
    return 'Preferences - Lyra Solar';
  }
  if (/profile/.test(path)) {
    return 'Profile - Lyra Solar';
  }
  if (/projects/.test(path)) {
    return '';
  }
  if (/register/.test(path)) {
    return 'Register - Lyra Solar';
  }
  if (/users/.test(path)) {
    return 'Users - Lyra Solar';
  }

  return 'Lyra Solar';
};

const TitleUpdater: FC = (): ReactElement => {
  const location = useLocation();

  useEffect(() => {
    const newTitle = titleBasedOnLocation(location.pathname);

    if (newTitle) {
      document.title = newTitle;
    }
  }, [location]);

  return <></>;
};

const thrower = (n?: number): void => {
  throw new Error(n ? 'host app instant error' : 'host app error');
};

export const throwWrapper = (n?: number): void => {
  thrower(n);
};

export default TitleUpdater;
