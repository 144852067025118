import { AnalyticsEvent } from '@aurorasolar/lyra-design-tool';
import type { IProject } from '../../models';
import type { IInstaller } from '../../models/IInstaller';
import type { IUser } from '../../stores/AuthStore';
import type { ILead } from '../../stores/LeadStore';
import { CrmIdentityProperties } from '../../utils/analytics';

/**
 * All events reported from Lyra Host App should extend this event.
 */
abstract class HostAppAnalyticsEvent extends AnalyticsEvent {
  override readonly source: string = 'LYRA_HOST_APP';
}

export class RegistrationAttemptedEvent extends HostAppAnalyticsEvent {
  override readonly name: string = 'Submitted registration form';

  constructor(email: string, firstName: string, lastName: string) {
    super({
      email,
      firstName,
      lastName
    });
  }
}

abstract class EventWithGenericCrmIdentityProperties extends HostAppAnalyticsEvent {
  constructor(user?: IUser, crmId?: string, lead?: ILead) {
    // Note: probably most of the properties are not really used, but we keep them for backwards-compatibility
    super(CrmIdentityProperties(user, crmId, lead));
  }
}

export class SignedUpEvent extends EventWithGenericCrmIdentityProperties {
  override readonly name: string = 'Signed up';
}

export class LeadConvertedToUserEvent extends EventWithGenericCrmIdentityProperties {
  override readonly name: string = 'Converted lead to user';
}

export class PasswordResetRequestedEvent extends HostAppAnalyticsEvent {
  override readonly name: string = 'Reset password';

  constructor(email: string) {
    super({
      email
    });
  }
}

export class LoginAttemptedEvent extends HostAppAnalyticsEvent {
  override readonly name: string = 'Submitted login form';

  constructor(email: string) {
    super({
      email
    });
  }
}

export class LoggedInEvent extends EventWithGenericCrmIdentityProperties {
  override readonly name: string = 'Logged in';
}

export class LoggedOutEvent extends EventWithGenericCrmIdentityProperties {
  override readonly name: string = 'Logged out';
}

export class AccountSetupCompletedEvent extends EventWithGenericCrmIdentityProperties {
  override readonly name: string = 'Completed first-time account setup';
}

export class UserProfileUpdatedEvent extends EventWithGenericCrmIdentityProperties {
  override readonly name: string = 'Updated profile';
}

/**
 * Note: this only covers Project creation from within the Host App.
 * Projects created via Design Tool are not tracked here.
 */
export class ProjectCreatedEvent extends HostAppAnalyticsEvent {
  override readonly name: string = 'Project Created';

  constructor(data: IProject, account: string) {
    super({
      projectId: data.id,
      account: `lyra:${account}`,
      address_state: data.address.province,
      address_city: data.address.city,
      address_postalCode: data.address.postalCode,
      source: 'HOST_APP'
    });
  }
}

abstract class ProjectEvent extends HostAppAnalyticsEvent {
  constructor(projectId: string) {
    super({
      id: projectId
    });
  }
}

export class ProjectUpdatedEvent extends ProjectEvent {
  override readonly name: string = 'Updated project details';
}

export class ProjectDeletedEvent extends ProjectEvent {
  override readonly name: string = 'Deleted project';
}

export class InstallerUpdatedEvent extends HostAppAnalyticsEvent {
  override readonly name: string = 'Updated installer details';

  constructor(data: IInstaller) {
    super({
      id: data.id,
      type: data.type,
      name: data.name
    });
  }
}

abstract class EventWithNoProperties extends HostAppAnalyticsEvent {
  constructor() {
    super({});
  }
}

export class BillingPaymentDetailsUpdatedEvent extends EventWithNoProperties {
  override readonly name: string = 'Updated billing payment details';
}

export class BillingDetailsUpdatedEvent extends EventWithNoProperties {
  override readonly name: string = 'Updated billing details';
}

export class PreferencesForDesignUpdatedEvent extends EventWithNoProperties {
  override readonly name: string = 'Updated design preferences';
}

export class PreferencesForDocumentsUpdatedEvent extends EventWithNoProperties {
  override readonly name: string = 'Updated document preferences';
}
