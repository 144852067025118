import config from '../config/config';
import type {
  ILoginParams, ISessionInfo, ISignUpParams, IUser
} from '../stores/AuthStore';
import type { IHttpServiceParams } from './generic/HttpService';
import HttpService from './generic/HttpService';

export interface IInitAccountParams {
  accountType: string;
  companyName?: string;
  primaryUse?: string;
}

class AuthService extends HttpService {
  constructor(params: IHttpServiceParams) {
    super(params);
  }

  login = (params: ILoginParams): Promise<ISessionInfo> => {
    return this.post<ILoginParams, ISessionInfo>({
      url: '/sessions',
      data: params,
      withAuth: false,
    });
  };

  signUp = (params: ISignUpParams): Promise<ISessionInfo> => {
    return this.post<ISignUpParams, ISessionInfo>({
      url: '/users',
      data: params,
      withAuth: false,
      showErrorsToUser: true,
    });
  };

  passwordReset = (email: string): Promise<void> => {
    return this.post({
      url: '/password-reset-requests',
      data: { email },
      withAuth: false,
    });
  };

  completeAccountSetup = (params: IInitAccountParams): Promise<ISessionInfo> => {
    return this.put({
      url: '/accounts/current/first-time-settings',
      data: params
    });
  };

  getProfile = (): Promise<IUser> => {
    return this.get<{}, IUser>({
      url: '/users/current/profile-info',
    });
  };

  updateProfile = (user: IUser): Promise<IUser> => {
    return this.put<IUser, IUser>({
      url: '/users/current/profile-info',
      data: user
    });
  };
}

export default new AuthService({
  baseURL: config.api.host
});
