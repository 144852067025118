import type {
  FC, ReactElement
} from 'react';
import React, {
  Suspense, lazy, useEffect
} from 'react';
import {
  BrowserRouter as Router, Route, Switch
} from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import {
  defaultThemeConfig, LyraTheme
} from '../components/Common/LyraComponents';
import { LoadingIndicator } from '../components/LoadingIndicator';
import { MainContainer } from '../components/MainContainer';
import { MODAL_PORTAL_ID } from '../components/Modal';
import PrivateRoute from '../components/Router/PrivateRoute';
import { DROPDOWN_PORTAL_ID } from '../constants';
import { useStore } from '../stores';
import loadHubSpotScriptIfNeeded from '../services/hubspot/hubspot-initial';
import TitleUpdater, { throwWrapper } from './TitleUpdater';

const Account = lazy(() => import('./Account'));
const Login = lazy(() => import('./auth/Login'));
const Register = lazy(() => import('./auth/Register'));
const ForgotPassword = lazy(() => import('./auth/ForgotPassword'));
const Dashboard = lazy(() => import('./Dashboard'));
const DesignTool = lazy(() => import('./DesignTool/DesignTool'));
const EditCompany = lazy(() => import('./EditCompany'));
const EditPayment = lazy(() => import('./EditPayment'));
const EditUser = lazy(() => import('./EditUser'));
const Preferences = lazy(() => import('./Preferences'));
const Users = lazy(() => import('./Users'));

const HostAppGlobalStyles = createGlobalStyle`
  body {
    line-height: 1;
    margin: 0;
    padding: 0;
    min-height: 100vh;
    max-height: 100vh;
  }
  
  #app {
    min-height: 100vh;
    max-height: 100vh;
  }
`;

const hostAppThemeConfig = {
  ...defaultThemeConfig,
  colors: {
    ...defaultThemeConfig.colors,
    mainColor1: '#2D80EF',
    searchIconGray: '#cfcfcf',
    alpha1: 'rgba(45, 128, 239, 0.5)',
    alpha2: 'rgba(133, 133, 133, 0.5)'
  },
  fonts: {
    fontFamily: 'Mukta, sans serif'
  }
};

const App: FC = (): ReactElement => {
  useEffect(() => {
    // Insert HubSpot Javascript into DOM if HubSpot is enabled
    loadHubSpotScriptIfNeeded();
  }, []);

  // Initialize user session in case if user already authorized and checked "Remember me" checkmark during login.
  const { auth: authStore } = useStore();
  useEffect(() => {
    try {
      authStore.restoreSession();
    } catch (error) {
      // Sometimes user can accidentally change session data in local storage, and it is not possible
      // to restore the session. In such case, we just fallback to /login, to re-write local storage data.
      // eslint-disable-next-line no-console
      console.error('Session exists, but not possible to restore. Probably session object is broken.', error);
    }
  }, [authStore]);

  let CrushButton = <></>;
  if (sessionStorage.getItem('sentryTestDrive') === 'on') {
    CrushButton = (
      <button
        onClick={() => {
          throwWrapper();
        }}
      >
        Break host app
      </button>
    );
  }

  useEffect(() => {
    if (sessionStorage.getItem('sentryTestDrive.host.instant') === 'on') {
      throwWrapper(2);
    }
  }, []);

  return (
    <>
      <HostAppGlobalStyles />
      <LyraTheme theme={hostAppThemeConfig}>
        {CrushButton}
        <Router>
          <TitleUpdater />
          <Suspense fallback={<LoadingIndicator />}>
            <Switch>
              <Route path="/" component={Login} exact />

              {/* TODO: remove index.html route in favor of htaccess rewrite rule */}
              <Route path="/index.html" component={Login} exact />

              <Route path="/register" component={Register} />
              <Route path="/login" component={Login} />
              <Route path="/forgot-password" component={ForgotPassword} />

              <PrivateRoute path="/projects/:projectId" component={DesignTool} />

              {/* TODO: Refactor router to have two different types of the layout */}
              <MainContainer>
                <PrivateRoute path="/dashboard" component={Dashboard} />

                <PrivateRoute path="/users" component={Users} exact />
                <PrivateRoute path="/profile" component={EditUser} />

                <PrivateRoute path="/account" component={Account} exact />
                <PrivateRoute path="/account/company/edit" component={EditCompany} exact />
                <PrivateRoute path="/account/payment/edit" component={EditPayment} exact />
                <PrivateRoute path="/preferences" component={Preferences} exact />
              </MainContainer>
            </Switch>
          </Suspense>

          <div id={MODAL_PORTAL_ID} />
          <div id={DROPDOWN_PORTAL_ID} />
        </Router>
      </LyraTheme>
    </>
  );
};

export default App;
