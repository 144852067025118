import { LyraSideBar } from '@aurorasolar/lyra-ui-kit';
import type {
  FC, ReactElement
} from 'react';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { validRoutes } from '../../config/config';

const PatternContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  background: url("../../assets/bg_2.png");
`;

const SidebarWrapper = styled.div`
  position: fixed;
`;

const dashboardRegexp = /dashboard\/?/;
const accountRegexp = /account\/?/;

const Sidebar: FC = (): ReactElement => {
  const history = useHistory();
  const { pathname } = history.location;

  const routeIsValid = validRoutes.some((route: RegExp) => route.test(history.location.pathname));
  const isValidDashboardRoute = dashboardRegexp.test(pathname) && routeIsValid;
  const isValidAccountdRoute = accountRegexp.test(pathname) && routeIsValid;

  const toggleActive = useCallback(
    (item: string) => {
      history.push(item);
    },
    [],
  );

  return (
    <SidebarWrapper>
      <LyraSideBar.SideBar>
        <LyraSideBar.NavItemLayout
          className="highlighted"
          icon={isValidDashboardRoute ? 'icon-dashboard' : 'icon-dashboard-white'}
          active={isValidDashboardRoute}
          onClick={(): void => toggleActive('/dashboard')}
        >
          DASHBOARD
        </LyraSideBar.NavItemLayout>
        <LyraSideBar.NavItemLayout
          className="highlighted"
          icon={isValidAccountdRoute ? 'icon-account' : 'icon-account-white'}
          active={isValidAccountdRoute}
          onClick={(): void => toggleActive('/account')}
        >
          Account
        </LyraSideBar.NavItemLayout>
        <PatternContainer />
      </LyraSideBar.SideBar>
    </SidebarWrapper>
  );
};

export { Sidebar };
