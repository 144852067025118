import config from '../../config/config';

const segmentEnabled = config.analytics.enabled && config.analytics.provider === 'segment';
if (segmentEnabled) {
  const SEGMENT_WRITE_KEY = config.analytics.id;
  /*
     * Note: this snippet is copied from Segment web UI:
     * ( Workspace -> Connections -> Sources -> Lyra App (client) -> Add Segment to your site )
     * and then modified in the following way:
     * - SEGMENT_WRITE_KEY is used instead of hardcoded key
     * - analytics.page(); call is removed (we expect each page to call it on its own)
     *
     * Also note: NPM package is also available: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/quickstart/#step-2b-install-segment-as-a-npm-package
     * If we switch to it, we could remove this script and also `devDependency` on `@types/segment-analytics`
     */
  !function (){
    var i='analytics',analytics=window[i]=window[i]||[];if (!analytics.initialize) {
      if (analytics.invoked){
        window.console&&console.error&&console.error('Segment snippet included twice.');
      } else {
        analytics.invoked=!0;analytics.methods=['trackSubmit','trackClick','trackLink','trackForm','pageview','identify','reset','group','track','ready','alias','debug','page','screen','once','off','on','addSourceMiddleware','addIntegrationMiddleware','setAnonymousId','addDestinationMiddleware','register'];analytics.factory=function (e){
          return function (){
            if (window[i].initialized) {
              return window[i][e].apply(window[i],arguments);
            } var n=Array.prototype.slice.call(arguments);if (['track','screen','alias','group','page','identify'].indexOf(e)>-1){
              var c=document.querySelector('link[rel=\'canonical\']');n.push({
                __t:'bpc',
                c:c&&c.getAttribute('href')||void 0,
                p:location.pathname,
                u:location.href,
                s:location.search,
                t:document.title,
                r:document.referrer
              });
            }n.unshift(e);analytics.push(n);return analytics;
          };
        };for (var n=0;n<analytics.methods.length;n++){
          var key=analytics.methods[n];analytics[key]=analytics.factory(key);
        }analytics.load=function (key,n){
          var t=document.createElement('script');t.type='text/javascript';t.async=!0;t.setAttribute('data-global-segment-analytics-key',i);t.src=`https://cdn.segment.com/analytics.js/v1/${ key }/analytics.min.js`;var r=document.getElementsByTagName('script')[0];r.parentNode.insertBefore(t,r);analytics._loadOptions=n;
        };
        analytics._writeKey=SEGMENT_WRITE_KEY;
        analytics.SNIPPET_VERSION='5.2.0';
        analytics.load(SEGMENT_WRITE_KEY);
      }
    }
  }();
}
